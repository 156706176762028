import http from '@http'

/**
 * 各tab总数统计
 * @param {*} params
 * @returns
 */
export function tabStatistics(params) {
  return http.get('/invoice/outsea/tab/statistics', params)
}

/**
 * 形式发票金额统计
 * @param {*} params
 * @returns
 */
export function pageStatistics(tab, params) {
  return http.get(`/invoice/outsea/statistics/${tab}`, params)
}

/**
 * 境外形式发票
 * @param {*} params
 * @returns
 */
export function getOutseaProformaPage(tab, params) {
  return http.get(`/invoice/outsea/${tab}`, params)
}

/**
 * 境外形式发票作废
 * @param {*} params
 * @returns
 */
export function outseaCancel(id) {
  return http.get(`/invoice/outsea/cancel/${id}`)
}

/**
 * 境外形式发票-查看
 * @param {*} params
 * @returns
 */
export function getPdfUrl(id) {
  return http.get(`/invoice/outsea/getPdfUrl/${id}`)
}

/**
 * 境外形式发票导出
 * @param {*} params
 * @returns
 */
export function outseaProformaExport(tab, params) {
  return http.downloadGet(`/invoice/outsea/export/${tab}`, params)
}

/**
 * 境外形式发票-确认
 * @param {*} params
 * @returns
 */
export function outseaConfirm(id, params) {
  return http.post(`/invoice/outsea/confirm/${id}`, params)
}
