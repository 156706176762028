<script>
import { computed } from '@vue/composition-api'
import { isFunction } from '@/@core/utils/is'
import { getUserData } from '@/auth/utils'

export default {
  name: 'ButtonList',
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    maxBtnNum: {
      type: Number,
      default: 3,
    },
    dropdownLabel: {
      type: String,
      default: '更多',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // 权限
    const userData = getUserData()
    const userRoles = userData.role || []

    // 过滤出有权限的按钮
    const newList = computed(() => props.list.filter(item => (!item.auth ? true : item.auth.some(value => userRoles.includes(value))) && (isFunction(item.hidden) ? item.hidden(props.item) : !item.hidden)))
    function separatedArray() {
      if (newList.value.length > props.maxBtnNum) {
        return newList.value.slice(0, props.maxBtnNum - 1)
      }

      return newList.value.slice(0, props.maxBtnNum)
    }

    function remainArray() {
      if (newList.value.length > props.maxBtnNum) {
        return newList.value.slice(props.maxBtnNum - 1)
      }

      return []
    }

    // 移除事件
    function removeEvent(item) {
      return {
        click: item.click ? () => item.click(props.item) : (() => {}),
      }
    }

    return {
      separatedArray,
      remainArray,
      removeEvent,
    }
  },
}
</script>
<template>
  <div class="menu-view">
    <!-- 按钮 -->
    <template v-for="(items, index) in separatedArray()">
      <span
        :key="index"
        v-auth="items.vAuth"
        class="app-link"
        v-on="removeEvent(items)"
      >
        {{ items.label }}
      </span>
    </template>
    <v-menu
      v-if="remainArray().length"
      offset-y
    >
      <template #activator="{ on }">
        <span
          class="app-link menuButton"
          v-on="on"
        >
          {{ dropdownLabel }}
          <v-icon
            color="#9155fd"
            size="17"
          >
            mdi-arrow-down-drop-circle-outline
          </v-icon>
        </span>
      </template>
      <v-list>
        <v-list-item
          v-for="(items, index) in remainArray()"
          :key="index"
          v-auth="items.vAuth"
        >
          <v-list-item-title
            style="cursor: pointer;"
            v-on="removeEvent(items)"
          >
            <div style="min-width: 80px" class="app-link">
              {{ items.label }}
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped lang='scss'>
.menu-view {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}
.menuButton {
  display: flex;
  align-items: center;
  gap: 4px;
  width: max-content;
}
</style>
