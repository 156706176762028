import http from '@http'

/**
 * 形式发票-批量变更汇款状态统计数据
 * @param {*} params
 * @returns
 */
export function proformaUpdateBatchCount(tab, data) {
  return http.post(`/invoice/management/proforma/proformaUpdateBatchCount/${tab}`, data)
}

/**
 * 形式发票-批量变更汇款状态
 * @param {*} params
 * @returns
 */
export function proformaUpdateBatchPaymentStatus(tab, data) {
  return http.post(`/invoice/management/proforma/proformaUpdateBatchPaymentStatus/${tab}`, data)
}

/**
 * 增值税发票-批量变更汇款状态统计数据
 * @param {*} params
 * @returns
 */
export function vatUpdateBatchCount(tab, data) {
  return http.post(`/invoice/management/vat/vatUpdateBatchCount/${tab}`, data)
}

/**
 * 形式发票-批量变更汇款状态
 * @param {*} params
 * @returns
 */
export function vatUpdateBatchPaymentStatus(tab, data) {
  return http.post(`/invoice/management/vat/vatUpdateBatchPaymentStatus/${tab}`, data)
}

/**
 * 境外形式发票-批量变更汇款状态统计数据
 * @param {*} params
 * @returns
 */
export function outseaUpdateBatchCount(tab, data) {
  return http.post(`/invoice/outsea/proformaUpdateBatchCount/${tab}`, data)
}

/**
 * 境外形式发票-批量变更汇款状态
 * @param {*} params
 * @returns
 */
export function outseaUpdateBatchPaymentStatus(tab, data) {
  return http.post(`/invoice/outsea/proformaUpdateBatchPaymentStatus/${tab}`, data)
}
