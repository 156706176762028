<script>
import { thumbnailSuffix } from '@/@core/utils/options'
import FormInput from '@/components/FormInput/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { asynchronousReturn } from '@/@core/utils'
import { required, requiredNonSpace } from '@/@core/utils/validation'
import { reactive, ref, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { outseaConfirm } from '../service'

export default {
  name: 'ConfirmDialog',
  components: { Dialog, FormInput },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { message } = useMessage()
    const show = ref(true)
    const formRef = ref(null)
    const fileInfoList = ref([])
    const formData = reactive({
      remark: undefined,
      fileInfoList: [],
    })

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const beforeUpload = file => {
      const chunkSize = 1024 * 1024 * 5
      const flag = file.some(item => item.size > chunkSize)
      if (flag) {
        message.warning('文件大小超过5MB')

        return false
      }

      if (file.length + fileInfoList.value.length > 5) {
        message.warning('最多上传5个附件')

        return false
      }

      return true
    }

    const searchConfig = computed(() => [
      {
        el: 'VTextarea', props: 'remark', label: '备注', counter: 200, maxlength: 200, clearable: false, cols: 2,
      },
      {
        el: 'Upload', props: 'fileInfoList', label: '附件', cols: 2, multiple: true, acceptType: `.pdf,${thumbnailSuffix}`, filesDetail: fileInfoList.value, beforeUpload, disabled: fileInfoList.value.length >= 5,
      },
    ])

    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        if (!fileInfoList.value.length) return message.warning('请上传附件')
        outseaConfirm(props.id, { remark: formData.remark, annexList: fileInfoList.value }).then(({ data }) => {
          console.log(data)
          if (data.status === 200) message.success('操作成功')
          emit('refresh')
          close()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    const delFile = (index, name) => {
      fileInfoList.value.splice(index, 1)
      message.success(`${name}已删除`)
    }

    const handleFileChange = ({ key, val }) => {
      if (key === 'fileInfoList') {
        const result = val.map(file => ({
          fileName: file.name,
          fileSize: file.size,
          filePath: file.key,
          bucketName: file.bucket,
          src: file.filePath,
        }))
        fileInfoList.value.unshift(...result)
      }
    }

    return {
      show,
      close,
      submit,
      handleFileChange,
      required,
      requiredNonSpace,
      formData,
      formRef,
      fileInfoList,
      searchConfig,
      delFile,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    show-footer
    title="确认"
    confirm-btn-text="确认"
    width="500px"
    @confirm="submit"
    @cancel="close"
  >
    <div class="reminder mb-5">
      当与CP线下取得确认后，可进行形式发票的代确认，是否确认？
    </div>
    <v-form ref="formRef">
      <FormInput :columns="searchConfig" :value="formData" @change="handleFileChange" @delFile="delFile"></FormInput>
    </v-form>
  </Dialog>
</template>
<style scoped lang='scss'>
.reminder{
  color: rgba(0,0,0,0.8);
}
</style>
