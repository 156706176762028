import http from '@http'

/**
 * 各tab总数统计
 * @param {*} params
 * @returns
 */
export function tabStatistics(params) {
  return http.get('/invoice/management/proforma/tab/statistics', params)
}

/**
 * 形式发票金额统计
 * @param {*} params
 * @returns
 */
export function pageStatistics(tab, params) {
  return http.get(`/invoice/management/proforma/statistics/${tab}`, params)
}

/**
 * 形式发票
 * @param {*} params
 * @returns
 */
export function getProformaPage(tab, params) {
  return http.get(`/invoice/management/proforma/${tab}`, params)
}

/**
 * 形式发票导出
 * @param {*} params
 * @returns
 */
export function proformaExport(tab, params) {
  return http.downloadGet(`/invoice/management/proforma/export/${tab}`, params)
}

/**
 * 形式发票作废
 * @id {*} id
 * @returns
 */
export function proformaCancel(id) {
  return http.get(`/invoice/management/cancel/${id}`)
}

/**
 * 形式发票-重新发起
 * @id {*} id
 * @returns
 */
export function proformaRegenerate(id) {
  return http.post(`/invoice/management/regenerate/${id}`)
}
