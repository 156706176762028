<template>
  <div>
    <v-menu
      v-model="menuActive"
      :close-on-content-click="false"
      :nudge-right="33"
      :disabled="disabled"
      transition="scale-transition"
      min-width="288px"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="dateTime"
          :label="title"
          :placeholder="title"
          :prepend-icon="prependIcon"
          :rules="isRequired ? [date ? (time ? true : '请选择时间！') : '请选择日期！'] : []"
          :disabled="disabled"
          :hide-details="hideDetails"
          readonly
          outlined
          dense
          :append-icon="dateTime ? 'mdi-close' : ''"
          v-bind="attrs"
          v-on="on"
          @click="openFlag = 1"
          @click:append="clearDateTime"
        ></v-text-field>
      </template>
      <div>
        <v-date-picker
          v-if="openFlag === 1"
          v-model="date"
          color="primary"
          locale="zh-cn"
          :disabled="disabled"
          :allowed-dates="allowDates ? allowDates : allowDateParse"
          @input="openFlag = 2"
          @change="$emit('update:date-time-model', dateTime)"
          @close="$emit('update:date-time-model', dateTime)"
        ></v-date-picker>

        <v-time-picker
          v-if="openFlag === 2"
          v-model="time"
          color="primary"
          format="24hr"
          full-width
          :disabled="disabled"
          :allowed-hours="allowHours ? allowHours : allowHourParse"
          :allowed-minutes="allowMinutes ? allowMinutes : allowMinuteParse"
          :use-seconds="seconds"
          :scrollable="scrollable"
          @input="$emit('update:date-time-model', dateTime)"
          @close="$emit('update:date-time-model', dateTime)"
        ></v-time-picker>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'DateTimePicker',
  model: {
    prop: 'dateTimeModel',
    event: 'update:date-time-model',
  },
  props: {
    dateTimeModel: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '请选择日期时间',
    },
    isRequired: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
      default: 'mdi-calendar',
    },
    seconds: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    scrollable: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    allowStart: {
      type: String,
      required: false,
      default: '',
    },
    allowEnd: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: [Boolean, Number],
      required: false,
      default: false,
    },
    hideDetails: {
      type: [Boolean, String],
      required: false,
      default: 'auto',
    },
    allowDates: {
      type: [Function, Boolean],
      required: false,
      default: false,
    },
    allowHours: {
      type: [Function, Boolean],
      required: false,
      default: false,
    },
    allowMinutes: {
      type: [Function, Boolean],
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const menuActive = ref(false)
    const openFlag = ref(1)
    const date = ref('')
    const time = ref('')
    const oneDay = 86400000
    const dateTime = computed({
      get() {
        return `${date.value ? `${date.value}` : ''}${time.value ? ` ${time.value}` : ''}`
      },
      set(val) {
        if (!val) {
          date.value = ''
          time.value = ''

          return
        }
        if (val.includes(' ')) {
          const dateTimeSplit = val.split(' ')
          if (props.seconds) {
            [date.value, time.value] = dateTimeSplit

            return
          }
          const timeSplit = dateTimeSplit[1].split(':')
          if (timeSplit.length === 3) timeSplit.pop();
          [date.value] = dateTimeSplit
          time.value = timeSplit.join(':')
        }
      },
    })
    dateTime.value = props.dateTimeModel
    emit('update:date-time-model', dateTime.value)

    function clearDateTime() {
      dateTime.value = ''
      emit('update:date-time-model', dateTime.value)
    }

    /* 允许日期的判断 */
    let allowStartDate = null
    let allowStartTime = null
    let allowEndDate = null
    let allowEndTime = null
    const allowDateParse = dateIn => {
      if (allowStartDate && allowEndDate) return (new Date(dateIn) > (new Date(allowStartDate) - oneDay)) && (new Date(dateIn) <= new Date(allowEndDate))
      if (allowStartDate) return new Date(dateIn) > new Date(allowStartDate) - oneDay
      if (allowEndDate) return new Date(dateIn) <= new Date(allowEndDate)

      return true
    }

    /* 允许时间的判断 */
    let allowStartTimeHour = null
    let allowStartTimeMinute = null
    let allowEndTimeHour = null
    let allowEndTimeMinute = null

    const allowHourParse = hourIn => {
      if (date.value === allowStartDate && date.value === allowEndDate) return hourIn >= allowStartTimeHour && hourIn <= allowEndTimeHour
      if (date.value === allowStartDate) return hourIn >= allowStartTimeHour
      if (date.value === allowEndDate) return hourIn <= allowEndTimeHour

      return true
    }
    const allowMinuteParse = minuteIn => {
      const hourChosen = parseInt(time.value.split(':')[0], 10)
      if (date.value === allowStartDate && date.value === allowEndDate && hourChosen === allowStartTimeHour && hourChosen === allowEndTimeHour) return minuteIn >= allowStartTimeMinute && minuteIn <= allowEndTimeMinute
      if (date.value === allowStartDate && hourChosen === allowStartTimeHour) return minuteIn >= allowStartTimeMinute
      if (date.value === allowEndDate && hourChosen === allowEndTimeHour) return minuteIn <= allowEndTimeMinute

      return true
    }

    watch([() => props.allowStart, () => props.allowEnd], () => {
      if (props.allowStart.includes(' ')) {
        [allowStartDate, allowStartTime] = props.allowStart.split(' ')
        if (allowStartTime) {
          [allowStartTimeHour, allowStartTimeMinute] = allowStartTime.split(':').map(i => parseInt(i, 10))
        }
      }
      if (props.allowEnd.includes(' ')) {
        [allowEndDate, allowEndTime] = props.allowEnd.split(' ')
        if (allowEndTime) {
          [allowEndTimeHour, allowEndTimeMinute] = allowEndTime.split(':').map(i => parseInt(i, 10))
        }
      }
    }, { immediate: true })

    return {
      date,
      time,
      dateTime,
      openFlag,
      menuActive,
      clearDateTime,
      allowDateParse,
      allowHourParse,
      allowMinuteParse,
    }
  },
}
</script>

<style scoped>

</style>
