import { paymentStatusOptions, ticketTypeOptions } from '@/@core/utils/options'

const mapEnum = {
  ticketTypeOptions,
  typeOptions: [
    { label: '全部', value: '' },
    { label: '电票', value: 1 },
    { label: '纸票', value: 2 },
  ],
  signStatusOptions: [
    { label: '待发起', value: 0 },
    { label: '待签字', value: 1 },
    { label: '已签字', value: 2 },
    { label: '待生成', value: 7 },
    { label: '发起失败', value: 5 },
  ],
  paymentStatusOptions,
}

export default mapEnum

export function filterMap(key, val) {
  const arr = mapEnum[key] || []
  const data = arr.find(item => item.value === val)

  return (data && data.label) || '-'
}

export const textMap = {
  7: '注：CP在YT/FB仍存在待审核的结算单，状态=待生成', // 待生成
  0: '注：CP在YT/FB的结算单全部变为【已推送】【暂不结算】后，状态=待发起，CP可在小程序发起签字', // 待发起
  5: '注：请根据失败原因对应处理', // 发起失败
  1: '', // 待签字
  2: '', // 已签字
}
