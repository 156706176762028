<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'Steps',
  components: { },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 1,
    },
    descMaxWidth: {
      type: Number,
      default: 120,
    },
    width: {
      type: String,
      default: '100%',
    },
  },
  setup(props, { emit }) {
    const totalWidth = computed(() => {
      if (typeof props.width === 'number') {
        return `${props.width}px`
      }

      return props.width
    })

    const totalSteps = computed(() => props.steps.length)

    const currentStep = computed(() => {
      if (props.value < 1) {
        return 1
      } if (props.value > totalSteps.value + 1) {
        return totalSteps.value + 1
      }

      return props.value
    })

    const onChange = index => {
      if (currentStep.value !== index) {
        // emit('input', index)
        emit('change', index)
      }
    }

    return {
      totalWidth,
      totalSteps,
      currentStep,
      onChange,
    }
  },
}
</script>
<template>
  <div class="m-steps-area" :style="`width: ${totalWidth};`">
    <div class="m-steps">
      <div
        v-for="(step, index) in steps"
        :key="index" :class="['m-steps-item',
                              {
                                'finish': currentStep > index + 1,
                                'process': currentStep === index + 1,
                                'wait': currentStep < index + 1
                              }
        ]"
      >
        <div class="m-info-wrap" @click="onChange(index + 1)">
          <div class="m-steps-icon">
            <span v-if="currentStep <= index + 1" class="u-num">{{ index + 1 }}</span>
            <svg v-else class="u-icon" viewBox="64 64 896 896" data-icon="check" aria-hidden="true" focusable="false"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg>
          </div>

          <div class="m-steps-content">
            <div class="u-steps-title">
              {{ step.title }}
            </div>
            <div v-if="step.checkForm" class="u-steps-description" :style="`max-width: ${descMaxWidth}px;`">
              <div class="name">
                操作者：{{ step.checkForm && step.checkForm.checkUser ? step.checkForm.checkUser : '暂无' }}
              </div>
              <div class="time">
                {{ step.checkForm && step.checkForm.checkAt ? step.checkForm.checkAt : '' }}
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.m-steps-area {
  margin: 0 auto;
  height: 120px;
  .m-steps {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    gap: 12px;
    .m-steps-item {
      display: inline-block;
      overflow: hidden;
      font-size: 16px;
      &:not(:last-child) {
        flex: 1; // 弹性盒模型对象的子元素都有相同的长度，且忽略它们内部的内容

        .u-steps-title {
          &::after {
            background: #e8e8e8;
            position: absolute;
            top: 16px;
            left: 100%;
            display: block;
            width: 3000px;
            height: 1px;
            content: "";
            cursor: auto;
            transition: all .3s;
          }
        }
      }
      .m-info-wrap {
        display: inline-block;
        .m-steps-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          text-align: center;
          background-color: transparent;
          color: #959596;
          border: 1px solid #959596;
          transition: all .3s;
          .u-num {
            display: inline-block;
            font-size: 16px;
            line-height: 1;
            color: #959596;
            transition: all .3s;
          }
          .u-icon {
            display: inline-block;
            fill: #9155FD;
            width: 16px;
            height: 16px;
          }
        }
        .m-steps-content {
          display: inline-block;
          vertical-align: top;
          position: relative;
          .u-steps-title {
            display: inline-block;
            padding-right: 16px;
            color: rgba(0, 0, 0, .45);
            line-height: 32px;
            transition: all .3s;
          }
          .u-steps-description {
            position: absolute;
            top: 40px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.50);
            line-height: 22px;
            word-break: break-all;
            transition: all .3s;
            .name{
                white-space: nowrap;
            }
            .time{
              max-width: 85px;
            }
          }
        }
      }
    }
    .finish {
      .m-info-wrap {
        cursor: pointer;
        .m-steps-icon {
          background-color: transparent;
          border-color: #9155FD;
        }
        .m-steps-content {
          .u-steps-title {
            color: rgba(0, 0, 0, .88);
            &::after {
              background: #9155FD;
            }
          }
          .u-steps-description {
            color: rgba(0, 0, 0, .45);
          }
        }
        // &:hover {
        //   .m-steps-icon {
        //     border-color:#9155FD;
        //   }
        //   .m-steps-content {
        //     .u-steps-title {
        //       color: #9155FD;
        //     }
        //     .u-steps-description {
        //       color: #9155FD;
        //     }
        //   }
        // }
      }
    }
    .process {
      .m-info-wrap {
        .m-steps-icon {
          background: #9155FD;
          border: 1px solid rgba(0, 0, 0, .25);
          border-color: #9155FD;
          .u-num {
            color: #fff;
          }
        }
        .m-steps-content {
          .u-steps-title {
            color: rgba(0, 0, 0, .88);
          }
          .u-steps-description {
            color: rgba(0, 0, 0, .88);
          }
        }
      }
    }
    .wait {
      .m-info-wrap {
        cursor: pointer;
        // &:hover {
        //   .m-steps-icon {
        //     border-color: #9155FD;
        //     .u-num {
        //       color: #9155FD;
        //     }
        //   }
        //   .m-steps-content {
        //     .u-steps-title {
        //       color: #9155FD;
        //     }
        //     .u-steps-description {
        //       color: #9155FD;
        //     }
        //   }
        // }
      }
    }
  }
}
</style>
