import http from '@http'

/**
 * 结算单各tab总数统计
 * @param {*} params
 * @returns
 */
export function tabStatistics(params) {
  return http.get('/settlement/page/tab/statistics', params)
}

/**
 * 结算单收益总计统计
 * @param {*} params
 * @returns
 */
export function pageStatistics(params) {
  return http.get('/settlement/page/statistics', params)
}

/**
 * 结算单生成记录列表
 * @param {*} params
 * @returns
 */
export function getPage(params) {
  return http.get('/settlement/page', params)
}

/**
 * 结算单列表导出
 * @param {*} params
 * @returns
 */
export function exportPage(type, params) {
  return http.downloadGet(`/settlement/export/${type}`, params)
}

/**
 * 审核详情（本月-上月结算单比对）
 * @param {Number} params id
 * @returns
 */
export function approvedDetail(id) {
  return http.get(`/settlement/approved/detail/${id}`)
}

/**
 * 结算单详情
 * @param {Number} params id
 * @returns
 */
export function detail(id) {
  return http.get(`/settlement/detail/${id}`)
}

/**
 * 批量重新生成
 * @param {} params
 * @returns
 */
export function batchRegenerate(params) {
  return http.post('/settlement/regenerate', params)
}

/**
 * 暂不结算
 * @param {Number} id
 * @returns
 */
export function noSettled(id, params) {
  return http.get(`/settlement/noSettled/${id}`, params)
}

/**
 * 批量暂不结算
 * @param {} params
 * @returns
 */
export function batchNoSettled(params) {
  return http.post('/settlement/noSettled', params)
}

/**
 * 暂不结算还原
 * @param {Number} params id
 * @returns
 */
export function reductionId(id) {
  return http.get(`/settlement/reduction/${id}`)
}

/**
 * 批量暂不结算还原
 * @param {Array} params ids
 * @param {String} params month
 * @returns
 */
export function batchReduction(params) {
  return http.post('/settlement/reduction', params)
}

/**
 * 审核通过
 * @param {Number} params id
 * @returns
 */
export function approved(id) {
  return http.get(`/settlement/approved/${id}`)
}

/**
 * 批量审核通过
 * @param {Array} params ids
 * @param {String} params month
 * @returns
 */
export function batchApproved(params) {
  return http.post('/settlement/approved', params)
}

/**
 * 重新生成
 * @param {Number} params id
 * @returns
 */
export function regenerate(id) {
  return http.post(`/settlement/regenerate/${id}`)
}
