<script>
import { asynchronousReturn } from '@/@core/utils'
import { useMessage } from '@/hooks/useMessage'
import { reactive, onMounted, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import Upload from '@/views/components/upload/Upload.vue'
import { required, requiredNonSpace, numberValidator10 } from '@/@core/utils/validation'
import RadioGroup from '@/views/components/form-components/radio-group/RadioGroup.vue'
import { adjustment } from './service'

export default {
  name: 'AdjustmentDialog',
  components: { Dialog, Upload, RadioGroup },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const { message } = useMessage()

    const formRef = ref()
    const formData = reactive({
      adjustAmount: undefined,
      adjustRemark: undefined,
      adjustCurrency: undefined,
    })

    const formulaTextMap = {
      CNY: '实发￥=ROUND(实发$*汇率，2)+调整项金额￥',
      USD: '实发$=应发$-Round（应发$*手续费率，2）+调整金额$',
    }

    const fileInfoList = ref([])

    const radioList = [
      { label: '人民币', value: 'CNY' },
      { label: '美元', value: 'USD' },
    ]
    const handleFileChange = files => {
      const result = files.map(file => ({
        fileName: file.name,
        fileSize: file.size,
        filePath: file.key,
        bucketName: file.bucket,
        src: file.filePath,
        isShow: 0,
      }))
      fileInfoList.value.unshift(...result)
    }

    const beforeUpload = file => {
      const chunkSize = 1024 * 1024 * 5
      const flag = file.some(item => item.size > chunkSize)
      if (flag) {
        message.warning('文件大小超过5MB')

        return false
      }

      if (file.length + fileInfoList.value.length > 5) {
        message.warning('最多上传5个附件')

        return false
      }

      return true
    }

    const delFile = (index, name) => {
      fileInfoList.value.splice(index, 1)
      message.success(`${name}已删除`)
    }

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        if (!fileInfoList.value.length) return message.warning('请上传附件')
        adjustment(props.form.id, { ...formData, annexList: fileInfoList.value }).then(({ data }) => {
          if (data.status === 200) message.success('操作成功')
          emit('refresh')
          close()
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    onMounted(() => {

    })

    return {
      show,
      close,
      submit,
      fileInfoList,
      handleFileChange,
      beforeUpload,
      delFile,
      formData,
      requiredNonSpace,
      required,
      numberValidator10,
      formRef,
      radioList,
      formulaTextMap,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    show-footer
    title="添加调整项"
    :subtitle-text="`(${form.channelName} ${form.subsetName ? `-${form.subsetName}` : ''})`"
    confirm-btn-text="确认"
    width="600px"
    @confirm="submit"
    @cancel="close"
  >
    <v-form ref="formRef">
      <v-row class="content">
        <v-col cols="3" class="mt5">
          调整币种
        </v-col>
        <v-col cols="7">
          <RadioGroup v-model="formData.adjustCurrency" :radio-list="radioList" row></RadioGroup>
        </v-col>
      </v-row>
      <v-row class="content">
        <v-col cols="3" class="mt5">
          调整金额
        </v-col>
        <v-col cols="7">
          <v-text-field
            v-model="formData.adjustAmount"
            placeholder="请输入"
            dense
            outlined
            :rules="[required,numberValidator10]"
            maxlength="10"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="content">
        <v-col cols="3">
          调整备注
        </v-col>
        <v-col cols="7">
          <v-textarea
            v-model="formData.adjustRemark"
            placeholder="请输入"
            dense
            outlined
            height="100px"
            counter="200"
            maxlength="200"
            :rules="[requiredNonSpace]"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row class="content">
        <v-col cols="3" class="mt5">
          调整依据附件
        </v-col>
        <v-col cols="7">
          <Upload
            :btn-config="{
              text: '上传文件',
              icon: 'icon-a-shangchuan2x',
            }"
            :files-detail="[fileInfoList]"
            :accept-type="'.doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png'"
            :before-upload="beforeUpload"
            :multiple="true"
            :disabled="fileInfoList.length >= 5"
            @change="handleFileChange"
          />
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="3">
      </v-col>
      <v-col>
        <div v-for="(item, index) in fileInfoList" :key="item.filePath" class="fileItem">
          <div class="mr10">
            {{ item.fileName }}
          </div>
          <div class="d-flex align-center">
            <v-switch
              v-model="item.isShow"
              inset
              dense
              :label="`${item.isShow ? '展示' : '不展示'}`"
              class="mt-0 mx-1 w110"
              hide-details
              :true-value="1"
              :false-value="0"
            ></v-switch>
            <i class="iconfont icon-a-shanchu2x fs13 pointer mt5" @click="delFile(index, item.fileName)"></i>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="remark font-weight-bold mt12">
      使用说明
    </div>
    <div class="remark">
      1、补款请输入正数，扣款请输入负数；计算公式为：{{ formulaTextMap[formData.adjustCurrency] }}
    </div>
    <div class="remark">
      2、附件需要展示给CP的，如：违约扣款依据截图，请勾选【展示】；不需要展示给CP的，如：钉钉审批流程截图，请勾选【不展示】
    </div>
    <div class="remark">
      3、附件支持上传doc、docx、xls、xlsx、图片、pdf，单份大小小于5M
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
.row + .row {
    margin-top: 0px;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{
  margin-bottom: -5px !important;
}

::v-deep .v-input--switch--inset .v-input--switch__track, .v-input--switch--inset .v-input--selection-controls__input{
  width: 38px !important;
}

::v-deep .v-input__slot{
  &:hover .theme--light.v-label{
    color: var(--v-primary-base);
  }
}

::v-deep .v-input--selection-controls__input{
  margin-right: 0 !important;
}
.content{
  font-weight: 500;
  font-size: 14px;
  color: rgba(0,0,0,0.8);
  display: flex;
  // align-items: center;
  .col-3::before{
    content: '*';
    color: #f5222d;
  }
}
.fileItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f6f7f8;
    padding: 6px 12px;
    margin-bottom: 6px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    div{
      word-break: break-all;
    }
    &:last-child{
      margin-bottom:0;
    }
  }
</style>
