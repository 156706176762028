import http from '@http'

/**
 * FB到账校验
 * @param {*} params
 * @returns
 */
export function verificationFB(params) {
  return http.get('/fbFinance/verification/page', params)
}

/**
 * FB到账校验导出-导出
 * @param {*} params
 * @returns
 */
export function verificationExportFB(params) {
  return http.downloadGet('/fbFinance/verification/export', params)
}
