<template>
  <div
    v-if="listItemsFiltered ? listItemsFiltered.length : false"
  >
    <component
      :is="`v-menu`"
      bottom
      left
    >
      <template #activator="{ on, attrs }">
        <slot
          name="activator"
          :on="on"
          :attrs="attrs"
        ></slot>
      </template>

      <v-list>
        <template
          v-for="(listItem, index) in listItemsFiltered"
        >
          <component
            :is="`v-list-item`"
            v-if="!getData(listItem.hidden, item)"
            :key="`${listItem.label}-${index}`"
            v-bind="listItem.componentProps"
            v-on="listItem.eventHandlers"
          >
            <v-list-item-title>
              <v-icon
                v-if="listItem.icon"
                size="20"
                class="me-2"
              >
                {{ getDataWithKey(listItem, 'icon') }}
              </v-icon>
              <span>{{ getDataWithKey(listItem, 'text') }}</span>
            </v-list-item-title>
          </component>
        </template>
      </v-list>
    </component>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getData, getDataWithKey } from '@core/utils/dataProcess'
import { VMenu, VList, VListItem } from 'vuetify/lib/components'

export default {
  name: 'IconMenuList',
  components: {
    VMenu,
    VList,
    VListItem,
  },
  props: {
    activatorIcon: {
      type: String,
      default: 'mdi-dots-vertical',
    },
    listItems: {
      type: Array,
      default: Array,
    },
    item: {
      type: Object,
      default: Object,
    },
  },
  setup(props) {
    const listItemsFiltered = computed(() => props.listItems.filter(item => !getDataWithKey(item.hidden)))

    return {
      listItemsFiltered,
      getDataWithKey,
      getData,
    }
  },
}
</script>

<style scoped>

</style>
