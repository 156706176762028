import mapEnum from './mapEnum'

const list = [
  { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', width: 200 },
  { text: 'Payout Period', value: 'payoutPeriod', width: 120 },
  { text: 'CP名称', value: 'cpName', width: 200, showOverflow: false },
  { text: '频道名称/ID', value: 'channelName', width: 250, showOverflow: false },
  { text: '子集名称', value: 'subsetName', width: 150, showOverflow: false },
  { text: '状态', value: 'checkStatus', width: 120 },
  { text: '发票状态', value: 'invoiceStatus', width: 120 },
]
const list1 = [
  { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', width: 200 },
  { text: 'Payout Period', value: 'payoutPeriod', width: 120 },
  { text: 'CP名称', value: 'cpName', width: 200, showOverflow: false },
  { text: '频道名称/ID', value: 'channelName', width: 250, showOverflow: false },
  { text: '子集名称', value: 'subsetName', width: 150, showOverflow: false },
  { text: '状态', value: 'checkStatus', width: 120 },
  { text: '频道收益 ($)', value: 'revenue', width: 120, align: 'right' },
]

const list2 = [
  { text: '收款人', value: 'bankAccountName', width: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', width: 200 },
  { text: '开户行', value: 'openingBank', width: 150 },
  { text: '收款账号', value: 'bankAccount', width: 200 },
  { text: '客服', value: 'customer', width: 100 },
]

const list3 = [
  { text: '结算单编号', value: 'settlementNo', width: 200 },
  { text: 'Payout Period', value: 'payoutPeriod', width: 120 },
  { text: 'CP名称', value: 'cpName', width: 200, showOverflow: false },
  { text: '频道名称/ID', value: 'channelName', width: 250, showOverflow: false },
  { text: '子集名称', value: 'subsetName', width: 150, showOverflow: false },
  { text: '状态', value: 'checkStatus', width: 120 },
  { text: '发票状态', value: 'invoiceStatus', width: 120 },
  { text: '汇款状态', value: 'paymentStatus', width: 120, showOverflow: false },
  { text: '频道收益 ($)', value: 'revenue', width: 120, align: 'right' },
]

const list4 = [
  { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', width: 200 },
  // { text: '月份', value: 'month', width: 100 },
  { text: '所属月份', value: 'month', width: 120 },
  { text: 'CP名称', value: 'cpName', width: 200, showOverflow: false },
  { text: '频道名称/ID', value: 'channelName', width: 250, showOverflow: false },
  { text: '子集名称', value: 'subsetName', width: 150, showOverflow: false },
  { text: '原因', value: 'remark', width: 180, showOverflow: false },
  { text: '频道收益 ($)', value: 'revenue', width: 120, align: 'right' },
]

export const tableColumns1 = [
  ...list1,
  { text: '美国收益 ($)', value: 'revenueUs', width: 120, align: 'right' },
  // { text: '联邦税率', value: 'federalTax', width: 120, align: 'right' },
  { text: '联邦税 ($)', value: 'federalTaxAmount', width: 120, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', width: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', width: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', width: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', width: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', width: 120, align: 'right' },
  { text: '汇率', value: 'rate', width: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', width: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', width: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', width: 120, align: 'right' },
  { text: '类型', value: 'settlementType', width: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
  { text: '操作', value: 'actions', width: 200, fixed: 'right' },
]

export const tableColumns2 = [
  ...list1,
  { text: 'CP分成比例', value: 'proportion', width: 100, align: 'right' },
  // { text: '联邦税率', value: 'federalTax', width: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', width: 120, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', width: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', width: 120, align: 'right' },
  { text: '类型', value: 'settlementType', width: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
  { text: '操作', value: 'actions', width: 160, fixed: 'right' },
]

export const tableColumns3 = [
  ...list,
  { text: '汇款状态', value: 'paymentStatus', minWidth: 120, showOverflow: false },
  { text: '频道收益 ($)', value: 'revenue', width: 120, align: 'right' },
  { text: '美国收益 ($)', value: 'revenueUs', width: 120, align: 'right' },
  // { text: '联邦税率', value: 'federalTax', width: 120, align: 'right' },
  { text: '联邦税 ($)', value: 'federalTaxAmount', width: 120, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', width: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', width: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', width: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', width: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', width: 120, align: 'right' },
  { text: '汇率', value: 'rate', width: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', width: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', width: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', width: 120, align: 'right' },
  { text: '类型', value: 'settlementType', width: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
]

export const tableColumns4 = [
  ...list4,
  { text: '美国收益 ($)', value: 'revenueUs', width: 120, align: 'right' },
  // { text: '联邦税率', value: 'federalTax', width: 120, align: 'right' },
  { text: '联邦税 ($)', value: 'federalTaxAmount', width: 120, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', width: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', width: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', width: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', width: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', width: 120, align: 'right' },
  { text: '汇率', value: 'rate', width: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', width: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', width: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', width: 120, align: 'right' },
  { text: '类型', value: 'settlementType', width: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
  { text: '操作', value: 'actions', width: 160, fixed: 'right' },
]

export const tableColumns5 = [
  ...list3,
  { text: '美国收益 ($)', value: 'revenueUs', width: 120, align: 'right' },
  // { text: '联邦税率', value: 'federalTax', width: 120, align: 'right' },
  { text: '联邦税 ($)', value: 'federalTaxAmount', width: 120, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', width: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', width: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', width: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', width: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', width: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', width: 120, align: 'right' },
  { text: '汇率', value: 'rate', width: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', width: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', width: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', width: 120, align: 'right' },
  { text: '类型', value: 'settlementType', width: 150, enum: mapEnum.statementTypeOptions },
  ...list2,
]
