const list = [
  { text: '编号', value: 'number', minWidth: 200, showOverflow: false },
  { text: 'CP名称', value: 'cpName', minWidth: 180, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, showOverflow: false },
  { text: '结算金额 ($)', value: 'revenue', minWidth: 150, showOverflow: false, align: 'right' },
  { text: '关联结算单', value: 'settlementList', minWidth: 200, showOverflow: false },
  { text: '状态', value: 'status', minWidth: 120, showOverflow: false },
]

export const tableColumns1 = [
  ...list,
  { text: '创建时间', value: 'createdAt', minWidth: 200 },
  { text: '最近更新时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 120, fixed: 'right' },
]

export const tableColumns2 = [
  ...list,
  { text: '创建时间', value: 'createdAt', minWidth: 200 },
  { text: '最近更新时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 200, fixed: 'right' },
]

export const tableColumns3 = [
  { text: '', value: 'checkbox', type: 'checkbox', width: 50 },
  ...list,
  { text: '汇款状态', value: 'paymentStatus', minWidth: 120, showOverflow: false },
  { text: '确认人', value: 'confirmedUser', minWidth: 120 },
  { text: '备注', value: 'remark', minWidth: 200, showOverflow: false },
  { text: '附件', value: 'annexList', minWidth: 280, showOverflow: false },
  { text: '确认时间', value: 'confirmedAt', minWidth: 200 },
  { text: '创建时间', value: 'createdAt', minWidth: 200 },
  { text: '最后修改人', value: 'updatedUserName', minWidth: 120 },
  { text: '最后修改时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 120, fixed: 'right' },
]
