<script>
import moment from 'moment'
import { ref, reactive } from '@vue/composition-api'
import TabsPro from '@/components/tabs/TabsPro.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import commonTable from './components/commonTable.vue'

export default {
  name: 'YTVerifyList',
  components: { TabsPro, TabPanePro, commonTable },
  props: {},
  setup() {
    const componentRef = ref()
    const activeName = ref('video')
    const searchForm = reactive({
      month: [moment().subtract(1, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')],
      channelId: undefined,
      cms: undefined,
      contentType: undefined,
      operation: undefined,
      title: undefined, // 频道名称
      uploaderType: undefined, // 是否CID self、thirdParty
    })

    const tabList = [
      { label: '月初视频级', value: 'video', element: 'commonTable', auth: ['ytVerify-early-month-export'] },
      { label: '财务报告频道级', value: 'report', element: 'commonTable', auth: ['ytVerify-financial-report-export'] },
      { label: '月初API频道级', value: 'channelBegin', element: 'commonTable', auth: ['ytVerify-early-month-api-export'] },
      { label: '月末API频道级', value: 'channelEnd', element: 'commonTable', auth: ['ytVerify-end-month-export'] },
    ]

    const tabClick = val => {
      activeName.value = val.name

      const index = tabList.findIndex(item => item.value === val.name)
      if (val.name !== 'video') {
        componentRef.value[index].isShow = true
      } else {
        componentRef.value[index].isShow = false
      }

      componentRef.value[index].search()
    }

    return {
      activeName,
      componentRef,
      tabClick,
      tabList,
      searchForm,

    }
  },
}
</script>
<template>
  <v-card class="app-list">
    <tabs-pro
      v-model="activeName"
      style="height: 100%;"
      @tabClick="tabClick"
    >
      <tab-pane-pro
        v-for="item in tabList"
        :key="item.value"
        :label="item.label"
        :name="item.value"
      >
        <component
          :is="item.element"
          ref="componentRef"
          :key="item.value"
          :form-data="searchForm"
          :tab="item.value"
          :tab-list="tabList"
          :active-name="activeName"
        >
        </component>
      </tab-pane-pro>
    </tabs-pro>
  </v-card>
</template>
<style scoped lang='scss'>
::v-deep .tab-content-item-box {
  height: calc( 100% - 50px);

}
</style>
