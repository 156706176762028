import http from '@http'

/**
 * 结算单详情
 * @param {Number} params id
 * @returns
 */
export function detail(no) {
  return http.get(`/settlement/detailByNo/${no}`)
}

/**
 * 结算单详情(Product)
 * @param {Number} params id
 * @returns
 */
export function productByNo(no) {
  return http.get(`/settlement/productByNo/${no}`)
}

/**
 * 调整项记录
 * @param {Number} params id
 * @returns
 */
export function adjustmentRecord(id) {
  return http.get(`/settlement/adjustment/record/${id}`)
}

/**
 * PDF下载
 * @param {Number} params id
 * @returns
 */
export function pdfDownload(id) {
  return http.get(`/settlement/pdf/download/${id}`)
}
