<template>
  <div
    :class="divClass"
  >
    <component
      :is="`VBtn`"
      v-for="(btn, index) in btnGroupFiltered"
      :key="`${btn.text}-${index}`"
      :class="getDataWithKey(btn.class)"
      :title="getDataWithKey(btn.title)"
      v-bind="btn.componentProps"
      v-on="btn.eventHandlers"
    >
      <v-icon
        v-if="getDataWithKey(btn.icon)"
        v-bind="btn.iconProps"
      >
        {{ getDataWithKey(btn.icon) }}
      </v-icon>
      {{ getDataWithKey(btn.text) }}
    </component>
  </div>
</template>

<script>
import { VBtn } from 'vuetify/lib/components'
import { getDataWithKey } from '@core/utils/dataProcess'
import { computed } from '@vue/composition-api'

export default {
  name: 'BtnGroup',
  components: {
    VBtn,
  },
  props: {
    btnGroupList: {
      type: Array,
      default: Array,
    },
    divClass: {
      type: [String, Object],
      default: '',
    },
  },
  setup(props) {
    const btnGroupFiltered = computed(() => props.btnGroupList.filter(btn => !btn.hidden))

    return {
      btnGroupFiltered,
      getDataWithKey,
    }
  },
}
</script>

<style scoped>

</style>
