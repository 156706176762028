<script>
import * as echarts from 'echarts'
import { onMounted, watch } from '@vue/composition-api'

export default {
  name: 'ChartBar',
  components: {},
  props: {
    chartItem: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    let chartInstance = {}

    function findMaxValue(data = []) {
      if (data.length === 0) {
        return undefined
      }

      return data.reduce((max, currentValue) => Math.max(max, currentValue))
    }

    function nearestMultipleOf5(number) {
      if (number < 1) {
        return 1
      }

      return Math.ceil(number / 5) * 5
    }

    function drawChart() {
      chartInstance = echarts.init(document.getElementById(`echartsDetail${props.chartItem.id}`))

      const option = {
        backgroundColor: 'transparent',
        grid: {
          top: 50,
          left: 80,
          bottom: 50,
          right: 80,
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(78,89,105,0.9)',
          textStyle: {
            color: '#fff',
          },
          borderColor: 'rgba(78,89,105,0.9)',
        },
        legend: {
          // 上方标题
          data: [{ name: '次数', icon: 'circle', backgroundColor: '#AD92FF' }, { name: '预估收入' }],
          right: 'auto',
          top: 15,
        },
        xAxis: [
          {
            type: 'category',
            data: props.chartItem.data?.dayX,
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '次数/万次',
            min: 0,
            max: nearestMultipleOf5(findMaxValue(props.chartItem.data?.view.map(item => item / 10000))),
            interval: nearestMultipleOf5(findMaxValue(props.chartItem.data?.view.map(item => item / 10000))) / 5, // 平均分为5份
            splitLine: {
              lineStyle: {
                type: 'dashed',
              },
            },
            axisLabel: {
              formatter: '{value}',
              interval: 4,
            },
          },
          {
            type: 'value',
            name: '预估收入/$',
            min: 0,
            max: nearestMultipleOf5(findMaxValue(props.chartItem.data?.revenue)),
            interval: nearestMultipleOf5(findMaxValue(props.chartItem.data?.revenue)) / 5, // 平均分为5份
            axisLabel: {
              formatter: '{value}',
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
              },
            },

          },
        ],
        series: [
          {
            name: '次数',
            type: 'bar',
            tooltip: {
              valueFormatter(value) {
                return `${value} 万次`
              },
            },
            data: props.chartItem.data?.view.map(item => item / 10000),
            itemStyle: {
              color: '#AD92FF',
            },
            barWidth: 18,
          },
          {
            name: '预估收入',
            type: 'line',
            tooltip: {
              valueFormatter(value) {
                return `${value} $`
              },
            },
            yAxisIndex: 1,
            data: props.chartItem.data?.revenue,
            itemStyle: {
              normal: {
                borderColor: '#F7C038', // symbol边框颜色
                color: '#F7C038',
                backgroundColor: '#fff',
                borderWidth: 2, // symbol边框宽度
              },
            },
            lineStyle: {
              color: '#F7C038',
            },
            symbol: 'circle',
            showSymbol: false,
          },
        ],
      }
      // 绘制图表
      chartInstance.setOption(option)
    }

    window.addEventListener('resize', () => {
      chartInstance.dispose()
      drawChart()
    })

    watch(
      () => props.chartItem,
      () => {
        chartInstance.dispose()
        drawChart()
      },
    )

    onMounted(() => {
      drawChart()
    })

    return {}
  },
}
</script>

<template>
  <div class="chart-box">
    <div :id="`echartsDetail${chartItem.id}`" style="height: 280px"></div>
  </div>
</template>

<style scoped lang="scss"></style>
