import http from '@http'

/**
 * 月初API频道级
 * @param {*} params
 * @returns
 */
export function channelBegin(params) {
  return http.get('/receiptCheck/channel/begin', params)
}

/**
 * 月末API频道级
 * @param {*} params
 * @returns
 */
export function channelEnd(params) {
  return http.get('/receiptCheck/channel/end', params)
}

/**
 * 财务报告频道级
 * @param {*} params
 * @returns
 */
export function report(params) {
  return http.get('/receiptCheck/report', params)
}

/**
 * 月初视频级
 * @param {*} params
 * @returns
 */
export function video(params) {
  return http.get('/receiptCheck/video', params)
}

/**
 * YT到账校验-统计
 * @param {*} params
 * @returns
 */
export function statistics(params) {
  return http.get('/receiptCheck/statistics', params)
}

/**
 * 月初API频道级-导出
 * @param {*} params
 * @returns
 */
export function channelBeginExport(params) {
  return http.downloadGet('/receiptCheck/channel/begin/export', params)
}

/**
 * 月末API频道级-导出
 * @param {*} params
 * @returns
 */
export function channelEndExport(params) {
  return http.downloadGet('/receiptCheck/channel/end/export', params)
}

/**
 * 财务报告频道级-导出
 * @param {*} params
 * @returns
 */
export function reportExport(params) {
  return http.downloadGet('/receiptCheck/report/export', params)
}

/**
 * 财务报告频道级-导出
 * @param {*} params
 * @returns
 */
export function videoExport(params) {
  return http.downloadGet('/receiptCheck/video/export', params)
}
