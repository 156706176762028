import { paymentStatusOptions, ticketTypeOptions } from '@/@core/utils/options'

const mapEnum = {
  ticketTypeOptions,
  typeOptions: [
    { label: '全部', value: '' },
    { label: '电票', value: 1 },
    { label: '纸票', value: 2 },
  ],
  checkStatusOptions: [
    { label: '待审核', value: 0 },
    { label: '审核通过', value: 1 },
    { label: '已驳回', value: -1 },
  ],
  paymentStatusOptions,
}

export default mapEnum

export function filterMap(key, val) {
  const arr = mapEnum[key] || []
  const data = arr.find(item => item.value === val)

  return (data && data.label) || '-'
}
