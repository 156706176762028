import { paymentStatusOptions } from '@/@core/utils/options'

const mapEnum = {
  statusOptions: [
    { label: '待生成', value: 0 },
    { label: '已生成', value: 1 },
    { label: '已确认', value: 2 },
    { label: '作废', value: 3 },
  ],
  paymentStatusOptions,
}

export default mapEnum

export function filterMap(key, val) {
  const arr = mapEnum[key] || []
  const data = arr.find(item => item.value === val)

  return (data && data.label) || '-'
}

export const textMap = {
  0: '注：CP在YT/FB仍存在待审核的结算单，状态=待生成', // 待发起
  1: '注：下载形式发票发送CP且CP确认无误后，可修改状态为已确认，生成汇款单', // 待签字
  2: '', // 已签字
}
