var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"show-footer":"","title":_vm.tab === 0 ? '查看并审核': '查看',"confirm-btn-text":_vm.tab === 0 ? '审核通过' : '保存',"width":"1000px","show-reject-btn-text":_vm.tab === 0 ? true :false},on:{"confirm":_vm.submit,"cancel":_vm.close,"reject":_vm.reject},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-form',{ref:"formRef"},[_c('DialogTable',{attrs:{"columns":_vm.viewHeader,"data":_vm.tableData,"item-height":50,"is-hover":false,"max-height":"550px","min-height":"50px"},scopedSlots:_vm._u([{key:"fileName",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',{staticClass:"download-link",on:{"click":function($event){return _vm.preview(row,index)}}},[_vm._v(_vm._s(row.fileName))])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('v-select',{staticClass:"my-1",attrs:{"items":_vm.mapEnum.ticketTypeOptions,"dense":"","outlined":"","hide-details":"","placeholder":"请选择","item-text":"label","item-value":"value","clearable":""},model:{value:(row.type),callback:function ($$v) {_vm.$set(row, "type", $$v)},expression:"row.type"}})]}},{key:"digitalTicketNumber",fn:function(ref){
var row = ref.row;
return [_c('v-text-field',{staticClass:"my-1",attrs:{"dense":"","outlined":"","hide-details":"","placeholder":"请输入","clearable":"","rules":row.digitalTicketNumber ? [_vm.validateNumberInput20] : []},model:{value:(row.digitalTicketNumber),callback:function ($$v) {_vm.$set(row, "digitalTicketNumber", $$v)},expression:"row.digitalTicketNumber"}})]}},{key:"invoiceCode",fn:function(ref){
var row = ref.row;
return [_c('v-text-field',{staticClass:"my-1",attrs:{"dense":"","outlined":"","hide-details":"","clearable":"","placeholder":"请输入","rules":row.invoiceNum ? [_vm.validateNumberInput12] : []},model:{value:(row.invoiceCode),callback:function ($$v) {_vm.$set(row, "invoiceCode", $$v)},expression:"row.invoiceCode"}})]}},{key:"invoiceNum",fn:function(ref){
var row = ref.row;
return [_c('v-text-field',{staticClass:"my-1",attrs:{"dense":"","outlined":"","hide-details":"","clearable":"","placeholder":"请输入","rules":row.invoiceCode ? [_vm.validateNumberInput8] : []},model:{value:(row.invoiceNum),callback:function ($$v) {_vm.$set(row, "invoiceNum", $$v)},expression:"row.invoiceNum"}})]}}])}),_c('v-divider',{staticClass:"my20"}),_c('FormInput',{attrs:{"columns":_vm.searchConfig,"value":_vm.formData},on:{"change":_vm.handleFileChange,"delFile":_vm.delFile}})],1),(_vm.rejectDialogVisible)?_c('rejectDialog',{attrs:{"id":_vm.id},on:{"close":function($event){_vm.rejectDialogVisible = false},"refresh":_vm.refresh}}):_vm._e(),(_vm.previewVisible)?_c('PreviewImg',{attrs:{"list":[_vm.filePath]},on:{"close":function($event){_vm.previewVisible = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }