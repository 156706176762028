<script>
import { formatNumber, processNumbers, addSpace } from '@/@core/utils'
import SearchForm from '@/components/SearchForm/index.vue'
import { ref, reactive, computed, onMounted, nextTick } from '@vue/composition-api'
import { downloadFile } from '@/@core/utils/form'
import { useMessage } from '@/hooks/useMessage'
import moment from 'moment'
import AppView from '@/components/AppView.vue'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import mapEnum, { apiMap, exportMap } from '../mapEnum'
import { statistics } from '../service'

export default {
  name: 'CommonTable',
  components: { SuperTable, Pagination, AppView, SearchForm },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    header: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: String,
      default: 'zg',
    },
    activeName: {
      type: String,
      default: 'zg',
    },
    tabList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const formRef = ref()
    const loading = ref(false)
    const { message } = useMessage()
    const searchForm = computed(() => props.formData).value

    const times = computed(() => ({
      monthStart: searchForm.month[0],
      monthEnd: searchForm.month[1],
    }))

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })
    const isShow = ref(false)
    const form = computed(() => [
      {
        el: 'DateMonthRange', props: 'month', label: '月份', type: 'month', clearable: false, isShow: true,
      },
      {
        el: 'VTextField', props: 'title', label: '频道名称', isShow: true,
      },
      {
        el: 'VTextField', props: 'channelId', label: '频道ID', isShow: true,
      },
      {
        el: 'VSelect', props: 'operation', label: '运营团队', items: ['video', 'report'].includes(props.tab) ? mapEnum.operationTextOptions : mapEnum.operationOptions, itemText: 'label', itemValue: 'value', isShow: true,
      },
      {
        el: 'VSelect', props: 'contentType', label: 'Content Type', items: mapEnum.contentTypeOptions, isShow: isShow.value, itemText: 'label', itemValue: 'value',
      },
      {
        el: 'VSelect', props: 'cms', label: 'CMS', items: mapEnum.cmsOptions, itemText: 'label', itemValue: 'value', isShow: true,
      },
      {
        el: 'VSelect', props: 'uploaderType', label: '是否CID', items: mapEnum.isCidOptions, itemText: 'label', itemValue: 'value', isShow: !isShow.value,
      },
    ])
    const searchConfig = computed(() => form.value.filter(item => item.isShow))

    const tableColumn = computed(() => [
      { text: '频道名称/ID', value: 'title', isShow: true, minWidth: 200 },
      { text: '月份', value: 'month', isShow: true },
      { text: '运营团队', value: 'operation', enum: mapEnum.operationOptions, isShow: true },
      { text: '是否CID', value: 'uploaderType', isShow: !isShow.value },
      { text: '频道收益（$）', value: 'revenue', isShow: true, align: 'right' },
      { text: '美国收益（$）', value: 'usRevenue', isShow: true, align: 'right' },
      { text: 'Content Type', value: 'contentType', isShow: isShow.value },
      { text: '收入类型', value: 'type', isShow: isShow.value },
      { text: 'CMS', value: 'cms', isShow: true, align: 'right' },
    ])

    const newTableColumn = computed(() => tableColumn.value.filter(item => item.isShow))

    const statisticsForm = ref({})
    const tableData = ref([])
    const init = () => {
      loading.value = true
      apiMap[props.tab]({ ...page, total: undefined, ...searchForm, ...times.value, month: undefined }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, newTableColumn.value)
        page.total = data.total
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const getStatisticsForm = () => {
      statistics({ ...page, total: undefined, ...searchForm, model: props.tab, ...times.value, month: undefined }).then(({ data }) => {
        statisticsForm.value = (data.data || {})
      }).catch(error => message.error(error.response.data.message))
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        searchForm.month = [moment().subtract(1, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')]
        init()
      })
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
      exportMap[props.tab]({ ...page, total: undefined, ...searchForm, ...times.value, month: undefined }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的${props.tabList.find(item => item.value === props.tab).label}报表`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => error)
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const search = () => {
      page.page = 1
      init()
      getStatisticsForm()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    onMounted(() => {
      if (props.activeName === props.tab) {
        init()
        getStatisticsForm()
      }
    })

    return {
      newTableColumn,
      searchConfig,
      formRef,
      page,
      tableData,
      reset,
      search,
      handleCurrentChange,
      handleSizeChange,
      statisticsForm,
      filterMap,
      exportExcel,
      isShow,
      searchForm,
      loading,
      processNumbers,
      addSpace,
    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div
        class="flex-vertical"
        style="width: 100%"
      >
        <SearchForm
          ref="formRef"
          :columns="searchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>

    <template #main-header>
      <div class="d-flex justify-space-between align-end">
        <div class="d-flex">
          <div v-for="item in tabList" :key="item.value" class="app-link">
            <v-btn v-if="item.value === tab" v-auth="item.auth" color="primary" @click="exportExcel">
              {{ addSpace('导出') }}
            </v-btn>
          </div>
        </div>

        <div class="num">
          频道收益($): <span> {{ processNumbers(statisticsForm.revenue) }} </span>
          美国收益($): <span> {{ processNumbers(statisticsForm.usRevenue) }} </span>
        </div>
      </div>
    </template>

    <SuperTable
      :columns="newTableColumn"
      :data="tableData"
      :loading="loading"
    >
      <template #title="{row}">
        <div>{{ row.title }}</div>
        <div>{{ row.channelId }}</div>
      </template>
      <template #operation="{row}">
        <span v-if="['video','report'].includes(tab)">
          {{ row.operation }}
        </span>
        <span v-else>
          {{ filterMap('operationOptions',row.operation) }}
        </span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </AppView>
</template>
  <style scoped lang='scss'>
  .num{
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 10px;
  span{
      color: #FFA80F;
      padding: 0 10px;
  }
  }
  </style>
