<template>
  <!-- 预览大图 -->
  <div class="preview_box" @wheel="handleScroll">
    <div class="bg_box" />
    <img
      class="img"
      :src="list[current]"
      :style="`transform:scale(${scale}) rotate(${rotate}deg);left:${positionX}px;top:${positionY}px`"
      @mousedown="onMouseDown"
      @mousemove="onMouseMove"
      @mouseup="onMouseUp"
    >
    <!-- 关闭按钮 -->
    <div
      class="large_image_but large_image_close"
      @click.stop="largeImageClick('close')"
    >
      <div>
        <div />
        <div />
      </div>
    </div>
    <!-- 左边图标 -->
    <template v-if="list.length > 1">
      <div
        class="large_image_but large_image_left"
        @click.stop="largeImageClick('left')"
      >
        <div>
          <div />
          <div />
        </div>
      </div>
      <!-- 右边图标 -->
      <div
        class="large_image_but large_image_right"
        @click.stop="largeImageClick('right')"
      >
        <div>
          <div />
          <div />
        </div>
      </div>
    </template>
    <!-- 底部控制 -->
    <div class="bot_box">
      <!-- 缩小 -->
      <div class="icon" @click.stop="handleScroll({ deltaY: 1 })">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="currentColor"
            d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zM352 448h256a32 32 0 0 1 0 64H352a32 32 0 0 1 0-64z"
          />
        </svg>
      </div>
      <!-- 放大 -->
      <div class="icon" @click.stop="handleScroll({ deltaY: -1 })">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="currentColor"
            d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z"
          />
        </svg>
      </div>
      <!-- 1比1 -->
      <div v-if="isIcon" class="icon" @click.stop="clickCenter">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="currentColor"
            d="m160 96.064 192 .192a32 32 0 0 1 0 64l-192-.192V352a32 32 0 0 1-64 0V96h64v.064zm0 831.872V928H96V672a32 32 0 1 1 64 0v191.936l192-.192a32 32 0 1 1 0 64l-192 .192zM864 96.064V96h64v256a32 32 0 1 1-64 0V160.064l-192 .192a32 32 0 1 1 0-64l192-.192zm0 831.872-192-.192a32 32 0 0 1 0-64l192 .192V672a32 32 0 1 1 64 0v256h-64v-.064z"
          />
        </svg>
      </div>
      <div v-else class="icon" @click.stop="clickCenter">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="currentColor"
            d="M813.176 180.706a60.235 60.235 0 0 1 60.236 60.235v481.883a60.235 60.235 0 0 1-60.236 60.235H210.824a60.235 60.235 0 0 1-60.236-60.235V240.94a60.235 60.235 0 0 1 60.236-60.235h602.352zm0-60.235H210.824A120.47 120.47 0 0 0 90.353 240.94v481.883a120.47 120.47 0 0 0 120.47 120.47h602.353a120.47 120.47 0 0 0 120.471-120.47V240.94a120.47 120.47 0 0 0-120.47-120.47zm-120.47 180.705a30.118 30.118 0 0 0-30.118 30.118v301.177a30.118 30.118 0 0 0 60.236 0V331.294a30.118 30.118 0 0 0-30.118-30.118zm-361.412 0a30.118 30.118 0 0 0-30.118 30.118v301.177a30.118 30.118 0 1 0 60.236 0V331.294a30.118 30.118 0 0 0-30.118-30.118zM512 361.412a30.118 30.118 0 0 0-30.118 30.117v30.118a30.118 30.118 0 0 0 60.236 0V391.53A30.118 30.118 0 0 0 512 361.412zM512 512a30.118 30.118 0 0 0-30.118 30.118v30.117a30.118 30.118 0 0 0 60.236 0v-30.117A30.118 30.118 0 0 0 512 512z"
          />
        </svg>
      </div>
      <!-- 左翻转 -->

      <div class="icon" @click.stop="rotate -= 90">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="currentColor"
            d="M289.088 296.704h92.992a32 32 0 0 1 0 64H232.96a32 32 0 0 1-32-32V179.712a32 32 0 0 1 64 0v50.56a384 384 0 0 1 643.84 282.88 384 384 0 0 1-383.936 384 384 384 0 0 1-384-384h64a320 320 0 1 0 640 0 320 320 0 0 0-555.712-216.448z"
          />
        </svg>
      </div>
      <!-- 右翻转 -->
      <div class="icon" @click.stop="rotate += 90">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="currentColor"
            d="M784.512 230.272v-50.56a32 32 0 1 1 64 0v149.056a32 32 0 0 1-32 32H667.52a32 32 0 1 1 0-64h92.992A320 320 0 1 0 524.8 833.152a320 320 0 0 0 320-320h64a384 384 0 0 1-384 384 384 384 0 0 1-384-384 384 384 0 0 1 643.712-282.88z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  /**
   * Swiper  预览图片
   * @description 本组件一般用于预览图片，需要自行控制是否显示，利用close自定义事件来关闭显示。
   * @property {Array}		list	 图片数组,数组里面直接放图片url 例：['http:***']
   * @property {Number}		index	 显示图片的下标
   * @event    {Function}      close              关闭按钮
   * @example  <Preview v-if="isShow" :list="list" :index="0" @close="isShow=false" />
   */
  props: {
    list: {
      default: () => [],
      type: Array,
    },
    index: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      isIcon: true,
      // 图片大小
      scale: 1,
      // 翻转角度
      rotate: 0,
      // 鼠标按住拖动图片
      isDragging: false,
      startPositionX: 0,
      startPositionY: 0,
      // 图片的具体定位
      positionX: 0,
      positionY: 0,
      // 当前图片的索引
      current: this.index,
    }
  },
  watch: {
    index(val) {
      this.current = val
    },
  },
  methods: {
    // 点击事件
    largeImageClick(type) {
      if (type === 'close') {
        this.$emit('close')
      } else if (type === 'left') {
        if (this.current === 0) {
          this.current = this.list.length - 1
        } else {
          this.current -= 1
        }
        this.clickCenter()
      } else if (type === 'right') {
        if (this.current === this.list.length - 1) {
          this.current = 0
        } else {
          this.current += 1
        }
        this.clickCenter()
      }
    },
    // 今天鼠标滚动
    handleScroll(e) {
      if (e.deltaY < 0) {
        this.scale += 0.1
      } else if (e.deltaY > 0) {
        if (this.scale <= 0.2) return
        this.scale -= 0.1
      }
    },
    // 图片恢复1比1
    clickCenter() {
      this.scale = 1
      this.rotate = 0
      this.positionX = 0
      this.positionY = 0
      this.isIcon = !this.isIcon
    },
    // 鼠标按住拖动图片
    onMouseDown(event) {
      this.isDragging = true
      this.startPositionX = event.clientX - this.positionX
      this.startPositionY = event.clientY - this.positionY
    },
    onMouseMove(event) {
      if (this.isDragging) {
        this.positionX = event.clientX - this.startPositionX
        this.positionY = event.clientY - this.startPositionY
      }
    },
    onMouseUp() {
      this.isDragging = false
    },
  },
}
</script>

<style scoped>
.preview_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2023;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview_box .bg_box {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0.5;
  background: rgb(0, 0, 0);
}

.preview_box .img {
  position: relative;
  transition: transform 0.3s 0s;
  height: 80%;
  /* 设置图片不能拖拽 */
  user-drag: none;
  -webkit-user-drag: none; /* 兼容 WebKit 浏览器（例如 Chrome、Safari） */
  -moz-user-drag: none; /* 兼容 Firefox 浏览器 */
  -ms-user-drag: none; /* 兼容 Internet Explorer 浏览器 */
  user-select: none;
}
/* 按钮背景颜色 */
.preview_box .large_image_but {
  background: #ccc;
  opacity: 0.8;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}
.preview_box .large_image_but div {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview_box .large_image_but div div {
  background: white;
  width: 24px;
  height: 3px;
  border-radius: 3px;
  position: absolute;
}
/* 关闭按钮 */
.preview_box .large_image_close {
  position: absolute;
  top: 50px;
  right: 50px;
}
.preview_box .large_image_close div div:nth-child(1) {
  transform: rotate(45deg);
  transform-origin: center;
}
.preview_box .large_image_close div div:nth-child(2) {
  transform: rotate(-45deg);
  transform-origin: center;
}
/* 左边按钮 */
.preview_box .large_image_left {
  position: absolute;
  left: 50px;
}
.preview_box .large_image_left div div:nth-child(1) {
  width: 18px;
  transform: rotate(35deg);
  transform-origin: left;
}
.preview_box .large_image_left div div:nth-child(2) {
  width: 18px;
  transform: rotate(-35deg);
  transform-origin: left;
}
/* 右边按钮 */
.preview_box .large_image_right {
  position: absolute;
  right: 50px;
}
.preview_box .large_image_right div div:nth-child(1) {
  width: 18px;
  transform: rotate(-35deg);
  transform-origin: right;
}
.preview_box .large_image_right div div:nth-child(2) {
  width: 18px;
  transform: rotate(35deg);
  transform-origin: right;
}
/* 底部控制盒子 */
.preview_box .bot_box {
  position: absolute;
  bottom: 32px;
  background: #000000;
  opacity: 0.8;
  width: 244px;
  height: 44px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 44px;
  padding: 0 24px;
}
/* 图标 */
.preview_box .bot_box .icon {
  width: 23px;
  height: 23px;
  color: white;
  cursor: pointer;
}
</style>
