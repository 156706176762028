import http from '@http'

/**
 * 结算单生成记录列表
 * @param {*} params
 * @returns
 */
export function getRecords(params) {
  return http.get('/settlement/records', params)
}
