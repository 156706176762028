import { operationOptionsFB, operationType, channelTypeOptions, contentTypeOptions, operationTextOptions } from '@/@core/utils/options'

const mapEnum = {
  operationOptions: operationOptionsFB,
  operationType,
  cmsOptions: [
    { label: 'XW', value: 'XW' },
    { label: 'AC', value: 'AC' },
  ],
  channelTypeOptions,
  contentTypeOptions,
  isCidOptions: [
    { label: 'self', value: 'self' },
    { label: 'thirdParty', value: 'thirdParty' },
  ],
  operationTextOptions,
  statusOptions: [
    { label: '已到账', value: 1 },
    { label: '应到未到', value: 2 },
    { label: '未到账', value: 3 },
  ],
}

export default mapEnum
