export const header = [
  { text: 'Payout Reference', value: 'payoutReference', width: 200 },
  { text: 'Product', value: 'product', width: 200 },
  { text: 'Payout Period', value: 'payoutPeriod', width: 120 },
  { text: 'Payout', value: 'payout', width: 150, align: 'right' },
  { text: 'Withholding Tax', value: 'withholdingTax', width: 180, align: 'right' },
  { text: 'Remittance', value: 'remittance', width: 150, align: 'right' },
  { text: '可分配收益', value: 'distributableIncome', width: 150, align: 'right' },
  { text: '应发收益 ($)', value: 'partnerRevenueDollar', width: 180, align: 'right' },
  { text: '手续费 ($)', value: 'taxDollar', width: 120, align: 'right' },
  { text: '实发收益 ($)', value: 'incomeTotal', width: 150, align: 'right' },
  { text: '汇率', value: 'rate', width: 80, align: 'right' },
  { text: '实发收益 (¥)', value: 'incomeRmb', width: 150, align: 'right' },
]

export const adjustmentHeader = [
  { text: '序号', value: 'seq', type: 'seq', width: 50 },
  { text: '调整金额', value: 'adjustAmount', align: 'right', width: 120, showOverflow: false },
  { text: '调整备注', value: 'adjustRemark', showOverflow: false },
  { text: '调整依据附件', value: 'annexList', showOverflow: false },
  { text: '操作者', value: 'adjustOperator', width: 100 },
  { text: '操作时间', value: 'createdAt', width: 200 },
]
