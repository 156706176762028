<script>
import { AliOSS } from '@/@core/utils/useAliOSS'
import PreviewImg from '@/components/PreviewImg/index.vue'
import { computed, ref } from '@vue/composition-api'
import Upload from '@/views/components/upload/Upload.vue'
import { VTextField, VSelect, VAutocomplete, VTextarea } from 'vuetify/lib/components'
import { isImgFormat } from '@/@core/utils/is'

export default {
  name: 'FormInput',
  components: { Upload, VTextField, VSelect, VAutocomplete, VTextarea, PreviewImg },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    // 绑定参数
    value: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const previewVisible = ref(false)
    const aliOss = new AliOSS()
    aliOss.fetchOSS()
    const searchParam = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    // 获取显示的搜索条件
    const columnsShow = computed(() => props.columns.filter(item => !item.hidden))

    const filePath = ref('')
    const preview = async item => {
      const data = await aliOss.fetchDownloadLink(item.filePath, item.fileName)
      if (isImgFormat(item.fileName)) {
        previewVisible.value = true
        filePath.value = data
      } else {
        window.open(data)
      }
    }

    const delFile = (index, name) => {
      emit('delFile', index, name)
    }

    const handleChange = (key, val) => {
      emit('change', { key, val })
    }

    return {
      searchParam, handleChange, delFile, preview, previewVisible, filePath, columnsShow,
    }
  },
}
</script>
<template>
  <div class="">
    <v-row v-for="column,index in columnsShow" :key="index" v-bind="column" class="content">
      <v-col :cols="column.cols || 3" :class="{ active:column.required}" class="label">
        {{ column.label }}
      </v-col>
      <v-col>
        <div class="d-flex align-center">
          <component
            :is="column.el"
            v-model.trim="searchParam[column.props]"
            outlined
            :clearable="column.clearable === undefined ? true : column.clearable"
            :placeholder="column.placeholder || '请输入'"
            dense
            label=""
            v-bind="column"
            @change="(val) => handleChange(column.props,val)"
          >
          </component>
          <div v-if="column.tips" class="tips">
            {{ column.tips }}
          </div>
        </div>

        <div v-if="column.el === 'Upload'" class="mt12">
          <div v-for="(item, i) in column.filesDetail" :key="item.filePath" class="fileItem">
            <div @click="preview(item)">
              {{ item.fileName }}
            </div>
            <i class="iconfont icon-a-shanchu2x fs13 mr5" @click="delFile(i, item.fileName)"></i>
          </div>
        </div>
      </v-col>
    </v-row>

    <PreviewImg
      v-if="previewVisible"
      :list="[filePath]"
      @close="previewVisible = false"
    />
  </div>
</template>
<style scoped lang='scss'>

.label{
  margin-top: 8px;
  &.active::before{
    content: '*';
    color: #F24545;
  }
}

.content{
  font-size: 16px;
  color: rgba(0,0,0,0.8);
  display: flex;
}

.row + .row {
    margin-top: 0px;
}

.fileItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f6f7f8;
    padding: 6px 12px;
    margin-bottom: 6px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    div{
      word-break: break-all;
      cursor: pointer;
    }
    i{
      cursor: pointer;
    }
    &:last-child{
      margin-bottom:0;
    }
}

::v-deep .upload-box{
  width: 15%;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details{
  margin-bottom: -10px !important;
}

::v-deep .v-input--switch--inset .v-input--switch__track, .v-input--switch--inset .v-input--selection-controls__input{
  width: 38px !important;
}

::v-deep .v-input--selection-controls__input{
  margin-right: 0 !important;
}
</style>
