import { operationOptions, operationType, channelTypeOptions, contentTypeOptions, operationTextOptions } from '@/@core/utils/options'
import { channelBegin, channelEnd, report, video, channelBeginExport, channelEndExport, reportExport, videoExport } from './service'

const mapEnum = {
  operationOptions,
  operationType,
  cmsOptions: [
    { label: 'XW', value: 'XW' },
    { label: 'AC', value: 'AC' },
  ],
  channelTypeOptions,
  contentTypeOptions,
  isCidOptions: [
    { label: 'self', value: 'self' },
    { label: 'thirdParty', value: 'thirdParty' },
  ],
  operationTextOptions,
}

export default mapEnum

export const apiMap = {
  channelBegin,
  channelEnd,
  report,
  video,
}

export const exportMap = {
  channelBegin: channelBeginExport,
  channelEnd: channelEndExport,
  report: reportExport,
  video: videoExport,
}
