<script>
import { isObject, isImgFormat } from '@/@core/utils/is'
import { addSpace, asynchronousReturn } from '@/@core/utils'
import { VDivider, VTextField } from 'vuetify/lib/components'
import { thumbnailSuffix } from '@/@core/utils/options'
import { useMessage } from '@/hooks/useMessage'
import { required, requiredNonSpace, validateNumberInput8, validateNumberInput12, validateNumberInput20 } from '@/@core/utils/validation'
import { onMounted, ref, reactive, nextTick, computed } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import DialogTable from '@/components/Dialog/DialogTable.vue'
import PreviewImg from '@/components/PreviewImg/index.vue'
import FormInput from '@/components/FormInput/index.vue'
import { AliOSS } from '@/@core/utils/useAliOSS'
import config from '../../../../../config'
import { getVatDetail, review } from '../service'
import mapEnum from '../mapEnum'
import { viewHeader } from '../tabCols'
import rejectDialog from './rejectDialog.vue'

export default {
  name: 'ViewAndReviewDialog',
  components: { Dialog, DialogTable, rejectDialog, PreviewImg, VDivider, VTextField, FormInput },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const rejectDialogVisible = ref(false)
    const previewVisible = ref(false)
    const { message, MessageBox } = useMessage()
    const show = ref(true)
    const formRef = ref(null)

    // const aliOss = new AliOSS()
    // aliOss.fetchOSS()

    const formData = reactive({
      reason: undefined,
      fileInfoList: [],
    })

    const fileInfoList = ref([])

    const isRule = ref(false)

    const beforeUpload = file => {
      const chunkSize = 1024 * 1024 * 5
      const flag = file.some(item => item.size > chunkSize)
      if (flag) {
        message.warning('文件大小超过5MB')

        return false
      }

      if (file.length + fileInfoList.value.length > 5) {
        message.warning('最多上传5个附件')

        return false
      }

      return true
    }

    const searchConfig = computed(() => [
      {
        el: 'VTextarea', props: 'reason', label: `${isRule.value ? '原因' : '备注'}`, placeholder: `请输入${isRule.value ? '原因' : '备注'}`, rules: isRule.value ? [requiredNonSpace] : [], counter: 200, maxlength: 200, clearable: false, cols: 1,
      },
      {
        el: 'Upload', props: 'fileInfoList', label: '附件', cols: 1, multiple: true, acceptType: `.doc,.docx,.xls,.xlsx,.pdf,${thumbnailSuffix}`, tips: '注：附件不会展示给CP，仅做记录', filesDetail: fileInfoList.value, beforeUpload, disabled: fileInfoList.value.length >= 5,
      },
    ])

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const tableData = ref([])

    const refresh = () => {
      close()
      emit('refresh')
    }

    const submit = () => {
      isRule.value = false
      nextTick(() => {
        asynchronousReturn(formRef.value.validate).then(() => {
          MessageBox({
            title: `${props.tab === 0 ? '审核通过' : '保存'}`,
            message: `您确定${props.tab === 0 ? '审核通过' : '保存'}吗？`,
            type: 'warning',
            showCancelButton: true,
            cancelBtnText: addSpace('取消'),
            confirmBtnText: addSpace('确定'),
          }).then(() => {
            review(props.id, { checkStatus: props.tab === 0 ? 1 : 0, details: tableData.value, reason: formData.reason, fileInfos: fileInfoList.value }).then(({ data }) => {
              if (data.status === 200) message.success('审核通过')
              refresh()
            }).catch(error => message.error(error.response.data.message))
          })
        }).catch(error => error)
      })
    }

    const reject = () => {
      isRule.value = true
      nextTick(() => {
        asynchronousReturn(formRef.value.validate).then(() => {
          MessageBox({
            title: '审核驳回',
            message: '您确定审核驳回吗？',
            type: 'warning',
            showCancelButton: true,
            cancelBtnText: addSpace('取消'),
            confirmBtnText: addSpace('确定'),
          }).then(() => {
            review(props.id, { checkStatus: -1, details: tableData.value, reason: formData.reason, fileInfos: fileInfoList.value }).then(({ data }) => {
              if (data.status === 200) message.success('驳回成功')
              refresh()
            }).catch(error => message.error(error.response.data.message))
          }).catch(error => error)
        }).catch(error => error)
      })
    }

    const filePath = ref()
    const infoObj = ref({
      bucketName: config.defaultBucket,
    })
    const preview = async item => {
      if (isObject(item.fileInfo)) {
        infoObj.value = item.fileInfo
      } else {
        infoObj.value = JSON.parse(item.fileInfo)
      }
      const aliOss = new AliOSS(infoObj.value.bucketName)
      await aliOss.fetchOSS()
      nextTick(async () => {
        const data = await aliOss.fetchDownloadLink(item.filePath, item.fileName)
        if (!isImgFormat(item.fileName)) {
          window.open(data)
        } else {
          previewVisible.value = true
          filePath.value = data
        }
      })
    }

    const delFile = (index, name) => {
      fileInfoList.value.splice(index, 1)
      message.success(`${name}已删除`)
    }

    const handleFileChange = ({ key, val }) => {
      if (key === 'fileInfoList') {
        const result = val.map(file => ({
          fileName: file.name,
          fileSize: file.size,
          filePath: file.key,
          bucketName: file.bucket,
          src: file.filePath,
          isShow: 0,
        }))
        fileInfoList.value.unshift(...result)
      }
    }

    const init = () => {
      getVatDetail(props.id).then(({ data }) => {
        tableData.value = data.data.details
        formData.reason = data.data.reason
        fileInfoList.value = data.data.reviewAnnex || []
      })
    }

    onMounted(() => {
      init()
    })

    return {
      show,
      close,
      submit,
      reject,
      formRef,
      viewHeader,
      tableData,
      mapEnum,
      validateNumberInput8,
      validateNumberInput12,
      validateNumberInput20,
      required,
      requiredNonSpace,
      rejectDialogVisible,
      previewVisible,
      preview,
      refresh,
      formData,
      searchConfig,
      isRule,
      handleFileChange,
      delFile,
      fileInfoList,
      filePath,
      infoObj,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    show-footer
    :title="tab === 0 ? '查看并审核': '查看'"
    :confirm-btn-text="tab === 0 ? '审核通过' : '保存'"
    width="1000px"
    :show-reject-btn-text="tab === 0 ? true :false"
    @confirm="submit"
    @cancel="close"
    @reject="reject"
  >
    <v-form ref="formRef">
      <DialogTable
        :columns="viewHeader"
        :data="tableData"
        :item-height="50"
        :is-hover="false"
        max-height="550px"
        min-height="50px"
      >
        <template #fileName="{row,index}">
          <span class="download-link" @click="preview(row,index)">{{ row.fileName }}</span>
        </template>
        <template #type="{row}">
          <v-select
            v-model="row.type"
            class="my-1"
            :items="mapEnum.ticketTypeOptions"
            dense
            outlined
            hide-details
            placeholder="请选择"
            item-text="label"
            item-value="value"
            clearable
          ></v-select>
        </template>
        <template #digitalTicketNumber="{row}">
          <v-text-field
            v-model="row.digitalTicketNumber"
            class="my-1"
            dense
            outlined
            hide-details
            placeholder="请输入"
            clearable
            :rules="row.digitalTicketNumber ? [validateNumberInput20] : []"
          ></v-text-field>
        </template>
        <template #invoiceCode="{row}">
          <v-text-field
            v-model="row.invoiceCode"
            class="my-1"
            dense
            outlined
            hide-details
            clearable
            placeholder="请输入"
            :rules=" row.invoiceNum ? [validateNumberInput12] : []"
          ></v-text-field>
        </template>
        <template #invoiceNum="{row}">
          <v-text-field
            v-model="row.invoiceNum"
            class="my-1"
            dense
            outlined
            hide-details
            clearable
            placeholder="请输入"
            :rules="row.invoiceCode ? [validateNumberInput8] : []"
          ></v-text-field>
        </template>
      </DialogTable>

      <v-divider class="my20"></v-divider>
      <FormInput :columns="searchConfig" :value="formData" @change="handleFileChange" @delFile="delFile"></FormInput>
    </v-form>

    <rejectDialog v-if="rejectDialogVisible" :id="id" @close="rejectDialogVisible = false" @refresh="refresh" />

    <PreviewImg
      v-if="previewVisible"
      :list="[filePath]"
      @close="previewVisible = false"
    />
  </Dialog>
</template>
<style scoped lang='scss'>
::v-deep .vxe-table--main-wrapper{
  border: 1px solid #E7ECF0;
  border-bottom: none;
}
::v-deep .vxe-table--render-default.border--default .vxe-body--column{
  border-right:1px solid #E7ECF0;

  &:last-child{
    border-right:none;
  }
}
::v-deep .vxe-table--render-default.border--default .vxe-header--column{
  border-right:1px solid #E7ECF0;

  &:last-child{
    border-right:none;
  }
}
</style>
