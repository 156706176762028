<template>
  <v-navigation-drawer
    :value="drawerActiveModel"
    :width="$vuetify.breakpoint.smAndUp ? width : '100%'"
    :permanent="permanent"
    :right="!$vuetify.rtl"
    :hide-overlay="hideOverlay"
    touchless
    temporary
    app
    @input="(val) => $emit('update:drawer-active-model', val)"
  >
    <v-card
      height="100%"
    >
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:drawer-active-model',false)"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-card-text
        class="ma-0 pa-0"
      >
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>

    <template #append>
      <slot name="append"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'AccountSetting',
  model: {
    prop: 'drawerActiveModel',
    event: 'update:drawer-active-model',
  },
  props: {
    drawerActiveModel: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '这是一个侧边弹框',
    },
    width: {
      type: [Number, String],
      default: 450,
    },
    permanent: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
  },
}
</script>

<style scoped>
</style>
