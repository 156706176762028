import http from '@http'

/**
 * 添加调整项
 * @param {*} params
 * @returns
 */
export function adjustment(id, params) {
  return http.post(`/settlement/adjustment/${id}`, params)
}
