<script>
import moment from 'moment'
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import TabsPro from '@/components/tabs/TabsPro.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import { tabStatistics } from './service'
import { tableColumns1, tableColumns2, tableColumns3, tableColumns4, tableColumns5 } from './tabCols'
import commonTable from './components/commonTable.vue'

export default {
  name: 'YTStatement',
  components: { TabsPro, TabPanePro, commonTable },
  props: {},
  setup() {
    const componentRef = ref()
    const activeName = ref('全部')
    const searchForm = reactive({
      month: [moment().subtract(3, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')],
      cpName: undefined,
      channelName: undefined,
      channelId: undefined,
      subsetName: undefined,
      settlementNo: undefined,
      settlementType: undefined,
      invoiceStatus: [],
      platform: 'YT',
      paymentStatus: undefined,
    })

    const times = computed(() => ({
      monthStart: searchForm.month[0],
      monthEnd: searchForm.month[1],
    }))

    const tabTotal = ref({})

    const tabList = computed(() => {
      const { customerCheckFirst, customerCheckSecond, financeCheck, notSettled, pushed } = tabTotal.value

      const arr = [customerCheckFirst, customerCheckSecond, financeCheck, notSettled, pushed]

      return [
        { label: '全部', value: 0, element: 'commonTable', columns: tableColumns5, total: arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 0 },
        { label: '待客服审核', value: 1, element: 'commonTable', columns: tableColumns2, total: customerCheckFirst || 0 },
        { label: '待财务审核', value: 2, element: 'commonTable', columns: tableColumns1, total: financeCheck || 0 },
        { label: '待客服二审', value: 3, element: 'commonTable', columns: tableColumns1, total: customerCheckSecond || 0 },
        { label: '已推送', value: 4, element: 'commonTable', columns: tableColumns3, total: pushed || 0 },
        { label: '暂不结算', value: 5, element: 'commonTable', columns: tableColumns4, total: notSettled || 0 },
      ]
    })

    const tabClick = val => {
      activeName.value = val.name
      const index = tabList.value.findIndex(item => item.label === val.name)
      componentRef.value[index].search()
    }

    const getTotal = settlementTab => {
      tabStatistics({ ...searchForm, ...times.value, month: undefined, invoiceStatus: settlementTab === 4 ? searchForm.invoiceStatus.join(',') : undefined, paymentStatus: [0, 4].includes(settlementTab) ? searchForm.paymentStatus : undefined }).then(({ data }) => {
        tabTotal.value = data.data
        if (!tabTotal.value.customerCheckFirst) tabTotal.value.customerCheckFirst = 0
        if (!tabTotal.value.financeCheck) tabTotal.value.financeCheck = 0
        if (!tabTotal.value.customerCheckSecond) tabTotal.value.customerCheckSecond = 0
        if (!tabTotal.value.pushed) tabTotal.value.pushed = 0
        if (!tabTotal.value.notSettled) tabTotal.value.notSettled = 0
      })
    }

    onMounted(() => {
      getTotal()
    })

    const updateTotal = tab => {
      getTotal(tab)
    }

    return {
      activeName,
      componentRef,
      tabClick,
      tabList,
      searchForm,
      updateTotal,
    }
  },
}
</script>
<template>
  <v-card class="app-list">
    <tabs-pro v-model="activeName" style="height: 100%" @tabClick="tabClick">
      <tab-pane-pro v-for="item in tabList" :key="item.label" :label="`${item.label}(${ item.total > 999 ? '999+' : item.total })`" :name="item.label">
        <component
          :is="item.element"
          ref="componentRef"
          :key="item.value"
          :header="item.columns"
          :form-data="searchForm"
          :tab="item.value"
          :label="item.label"
          :active-name="activeName"
          @updateTotal="updateTotal"
        >
        </component>
      </tab-pane-pro>
    </tabs-pro>
  </v-card>
</template>
<style scoped lang="scss">
::v-deep .tab-content-item-box {
  height: calc(100% - 50px);
}
</style>
