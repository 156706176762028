<script>
import { useMessage } from '@/hooks/useMessage'
import { asynchronousReturn } from '@/@core/utils'
import { requiredNonSpace } from '@/@core/utils/validation'
import { reactive, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { noSettled, batchNoSettled } from '../service'

export default {
  name: 'NoSettlementDialog',
  components: { Dialog },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { message } = useMessage()
    const show = ref(true)
    const formRef = ref(null)
    const formData = reactive({
      remark: undefined,
    })
    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        if (props.form.isBatch) {
          batchNoSettled({ ids: props.form.ids, ...formData }).then(({ data }) => {
            if (data.status === 200) {
              message.success('操作成功')
              emit('refresh')
              close()
            }
          }).catch(error => message.error(error.response.data.message))
        } else {
          noSettled(props.form.id, { ...formData }).then(({ data }) => {
            if (data.status === 200) {
              message.success('操作成功')
              emit('refresh')
              close()
            }
          }).catch(error => message.error(error.response.data.message))
        }
      }).catch(error => error)
    }

    return {
      show,
      close,
      submit,
      requiredNonSpace,
      formData,
      formRef,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    show-footer
    :title="`${form.isBatch ? '批量暂不结算' : '暂不结算'}`"
    confirm-btn-text="确认"
    width="450px"
    @confirm="submit"
    @cancel="close"
  >
    <v-form ref="formRef">
      <div class="d-flex align-start">
        <span class="px-2">理由</span>
        <v-textarea
          v-model="formData.remark"
          placeholder="请输入"
          outlined
          height="150px"
          counter="100"
          :rules="[requiredNonSpace]"
          maxlength="100"
        ></v-textarea>
      </div>
    </v-form>
  </Dialog>
</template>
<style scoped lang='scss'>
span::before{
    content: '*';
    color: #f00;
}
</style>
