<template>
  <div>
    <v-avatar
      rounded
      color="primary"
      class="v-avatar-light-bg primary--text"
      :style="`height: ${height}; width: ${width};`"
    >
      <v-img
        v-if="src"
        :src="src"
        :alt="alt"
        :eager="true"
        position="center center"
      >
        <span
          v-if="tag"
          class="video-time"
          :class="{ 'video-time-dark': dark, 'video-time-light': !dark }"
        >{{ tag }}</span>
      </v-img>
      <span
        v-else
      >{{ alt ? alt.slice(0, 5).toUpperCase() : '' }}</span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: 'ImgWithTag',
  props: {
    alt: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '50px',
    },
    width: {
      type: String,
      default: '88px',
    },
    dark: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.video-time {
  position: absolute;
  top: 1px;
  right: 2px;
  font-size: 1px !important;
  border-radius: 1px;
  padding: 0 3px;
  font-weight: bold;
}
.video-time-dark {
  background-color: #3b3435;
}
.video-time-light {
  background-color: seashell;
}
</style>
