<script>

import { formatNumber, addSpace } from '@/@core/utils'
import { downloadFile } from '@/@core/utils/form'
import { useMessage } from '@/hooks/useMessage'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import { ref, reactive, computed, onMounted, watch, nextTick } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import { tableColumn } from './tabColumn'
import { verificationFB, verificationExportFB } from './service'
import mapEnum from './mapEnum'

export default {
  name: 'FBVerifyList',
  components: { AppView, SearchForm, SuperTable, Pagination },
  props: {},
  setup() {
    const loading = ref(false)
    const { message } = useMessage()
    const formRef = ref()
    const activeName = ref('video')
    const searchForm = reactive({
      name: undefined,
      pageId: undefined,
      status: undefined,
      // periodStartMonth: undefined,
      // periodEndMonth: undefined,
      // receiptStartMonth: undefined,
      // receiptEndMonth: undefined,
      period: [],
      receipt: [],
    })

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const times = computed(() => ({
      periodStartMonth: searchForm.period[0],
      periodEndMonth: searchForm.period[1],
      receiptStartMonth: searchForm.receipt[0],
      receiptEndMonth: searchForm.receipt[1],
    }))
    const searchConfig = computed(() => [
      {
        el: 'DateMonthRange',
        props: 'period',
        label: 'Payout Period',
        placeholder: '筛选Payout Period',
        clearable: true,
      },
      {
        el: 'DateMonthRange',
        props: 'receipt',
        label: '到账时间',
        placeholder: '到账时间',
        clearable: true,
      },
      {
        el: 'VTextField', props: 'name', label: 'Object Name',
      },
      {
        el: 'VTextField', props: 'pageId', label: 'Object ID',
      },
      {
        el: 'VSelect', props: 'status', label: '到账状态', items: mapEnum.statusOptions, itemText: 'label', itemValue: 'value',
      },

    ])

    const tableData = ref([])
    const init = () => {
      loading.value = true
      const { period, receipt, ...args } = searchForm
      verificationFB({ ...page, total: undefined, ...args, ...times.value }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, tableColumn)
        page.total = data.total
      })
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        searchForm.period = []
        searchForm.receipt = []
        init()
      })
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
      const { period, receipt, ...args } = searchForm
      verificationExportFB({ ...page, total: undefined, ...args, ...times.value }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的FB到账校验报表`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => error)
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const search = () => {
      page.page = 1
      init()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    onMounted(() => {
      init()
    })

    watch(() => searchForm.month, newVal => {
      searchForm.month = newVal
    })

    return {
      loading,
      formRef,
      tableColumn,
      activeName,
      page,
      searchForm,
      searchConfig,
      tableData,
      reset,
      search,
      handleCurrentChange,
      handleSizeChange,
      filterMap,
      exportExcel,
      addSpace,
    }
  },
}
</script>
<template>
  <AppView class="px-5 pt-5">
    <template #header>
      <div style="width: 100%">
        <SearchForm
          ref="formRef"
          class="pa-0 pb-5"
          :columns="searchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>
    <template #main-header>
      <div class="">
        <v-btn v-auth="['fbVerify-export']" color="primary" @click="exportExcel">
          {{ addSpace('导出') }}
        </v-btn>
      </div>
    </template>
    <SuperTable
      :columns="tableColumn"
      :data="tableData"
      :loading="loading"
    >
      <template #objectId="{row}">
        <div>{{ row.objectName }}</div>
        <div>{{ row.objectId }}</div>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </AppView>
</template>
<style scoped lang='scss'>
.num{
color: rgba($color: #000000, $alpha:0.8);
padding: 0 10px;
span{
    color: #FFA80F;
    padding: 0 10px;
}
}
</style>
