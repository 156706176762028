import mapEnum from './mapEnum'

export const tableColumn = [
  { text: 'Payout Reference', value: 'payoutReference', width: 180 },
  { text: 'Payout ID', value: 'payoutId', width: 180 },
  { text: 'StateMent', value: 'statement', width: 120 },
  { text: 'Product', value: 'product', width: 180 },
  { text: 'Payout Period', value: 'payoutPeriod', width: 120 },
  { text: 'Object Name/ID', value: 'objectId', width: 180 },
  { text: '运营部门', value: 'operationDept', width: 120, enum: mapEnum.operationOptions },
  { text: '主页属性', value: 'operationType', width: 100, enum: mapEnum.operationType },
  { text: 'Amount', value: 'amount', width: 100, align: 'right' },
  { text: 'Payout', value: 'payout', width: 100, align: 'right' },
  { text: 'Withholding Tax', value: 'withholdingTax', width: 150, align: 'right' },
  { text: 'Remittance', value: 'remittance', width: 120, align: 'right' },
  { text: '到账期', value: 'month', width: 120 },
  { text: '到账状态', value: 'status', width: 120, enum: mapEnum.statusOptions },
]
