<script>
import { useMessage } from '@/hooks/useMessage'
import { asynchronousReturn } from '@/@core/utils'
import { required, requiredNonSpace } from '@/@core/utils/validation'
import { reactive, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { review } from '../service'

export default {
  name: 'RejectDialog',
  components: { Dialog },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { message } = useMessage()
    const show = ref(true)
    const formRef = ref(null)
    const formData = reactive({
      reason: undefined,
    })

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      asynchronousReturn(formRef.value.validate).then(() => {
        review(props.id, { checkStatus: -1, ...formData }).then(({ data }) => {
          if (data.status === 200) message.success('驳回成功')
          emit('refresh')
        }).catch(error => message.error(error.response.data.message))
      }).catch(error => error)
    }

    const reject = () => {}

    return {
      show,
      close,
      submit,
      reject,
      required,
      requiredNonSpace,
      formData,
      formRef,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    show-footer
    title="驳回"
    confirm-btn-text="确认"
    width="450px"
    @confirm="submit"
    @cancel="close"
    @reject="reject"
  >
    <v-form ref="formRef">
      <div class="d-flex align-start">
        <span class="px-2">原因</span>
        <v-textarea
          v-model.trim="formData.reason"
          placeholder="请输入"
          outlined
          height="150px"
          counter="200"
          :rules="[requiredNonSpace]"
          maxlength="200"
        ></v-textarea>
      </div>
    </v-form>
  </Dialog>
</template>
<style scoped lang='scss'>
span::before{
    content: '*';
    color: #f00;
}
</style>
