<script>
import { platformUnitMap } from '@/@core/utils/constant'
import { processNumbers } from '@/@core/utils'
import { onMounted, ref, reactive } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { useMessage } from '@/hooks/useMessage'
import { getBatchCountMap, submitBatchPaymentStatusMap } from './mapEnum'

export default {
  name: 'BatchChangeRemitDialog',
  components: { Dialog },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    platform: {
      type: String,
      required: true,
    },
    tab: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(true)

    const formData = reactive({
      count: 0,
      pendingPaymentCount: 0,
      reduce: 0,
    })

    const { message } = useMessage()

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const init = async () => {
      try {
        const { data } = await getBatchCountMap[props.platform](props.tab, props.form)
        Object.assign(formData, data.data)
      } catch (error) {
        message.error(error.response.data.message)
      }
    }
    const submit = () => {
      submitBatchPaymentStatusMap[props.platform](props.tab, props.form).then(({ data }) => {
        if (data.status === 200) message.success('操作成功')
        emit('refresh')
        close()
      }).catch(error => message.error(error.response.data.message))
    }

    onMounted(() => {
      init()
    })

    return {
      show,
      close,
      submit,
      formData,
      processNumbers,
      platformUnitMap,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    :title="`批量变更汇款途径`"
    width="600px"
    show-footer
    confirm-btn-text="确认"
    @cancel="close"
    @confirm="submit"
  >
    <div class="pl30">
      <div class="label">
        已选择 <span class="app-link">{{ formData.count }}</span> 条，待汇款<span class="app-link">{{ formData.pendingPaymentCount }}</span> 条
      </div>
      <div class="label">
        金额：<span class="app-number">{{ processNumbers(formData.reduce,platformUnitMap[platform]) }}</span>
      </div>
      <div class="label">
        是否变更为已汇款？关联的结算单会同步变为已汇款
      </div>
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
.label{
  color: rgba(0,0,0,0.85);
  font-size: 16px;
}
.label  + .label{
  margin-top: 12px;
}
</style>
