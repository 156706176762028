<template>
  <div>
    <template
      v-for="(btnItem, index) in btnList"
    >
      <component
        :is="`TooltipBase`"
        v-if="!getData(btnItem.hidden, item)"
        :key="`${btnItem.label}-${index}`"
        :title="getDataWithKey(btnItem.text)"
        v-bind="btnItem.tipProps"
      >
        <component
          :is="`VBtn`"
          icon
          v-bind="btnItem.componentProps"
          v-on="btnItem.eventHandlers"
        >
          <v-icon>{{ getDataWithKey(btnItem.icon) }}</v-icon>
        </component>
      </component>
    </template>
  </div>
</template>

<script>
import TooltipBase from '@/views/components/common/tooltip-base/TooltipBase.vue'
import { VBtn } from 'vuetify/lib/components'
import { getData, getDataWithKey } from '@core/utils/dataProcess'

export default {
  name: 'IconBtnGroup',
  components: {
    VBtn,
    TooltipBase,
  },
  props: {
    btnList: {
      type: Array,
      default: Array,
    },
    item: {
      type: Object,
      default: Object,
    },
  },
  setup() {
    return {
      getData,
      getDataWithKey,
    }
  },
}
</script>

<style scoped>

</style>
