import { operationType, channelTypeOptions, operationAddOptions } from '@/@core/utils/options'
import { estimateFB, invoiceFB, estimateExportFB, invoiceExportFB, remittancePageFb, remittanceExportFB } from './service'

const mapEnum = {
  operationOptions: operationAddOptions,
  operationType,
  channelTypeOptions,
  hasContractOptions: [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ],
  statusOptions: [
    { label: '全部', value: '' },
    { label: '未生成', value: 0 },
    { label: '已生成', value: 1 },
    { label: '无需生成', value: 2 },
  ],
}

export default mapEnum

export const apiMap = {
  zg: estimateFB,
  total: invoiceFB,
  hundred: invoiceFB,
  remittance: remittancePageFb,
  ltHundred: invoiceFB,
}

export const exportMap = {
  zg: estimateExportFB,
  total: invoiceExportFB,
  hundred: invoiceExportFB,
  remittance: remittanceExportFB,
  ltHundred: invoiceExportFB,
}
