<template>
  <div class="pa-0 ma-0">
    <!-- <div v-if="isFinite">
      {{ getDataWithKey(label, 'value') }}
    </div> -->

    <component
      :is="`v-radio-group`"
      v-if="!getDataWithKey(radioGroupProps.hidden, 'value')"
      v-model="data"
      :disabled="getDataWithKey(disabled)"
      :rules="rules"
      :row="row"
      hide-details
      dense
      mandatory
      v-bind="radioGroupProps.componentProps"
      v-on="radioGroupProps.eventHandlers"
    >
      <!-- <div v-if="!isFinite">
        {{ getDataWithKey(label, 'value') }}
      </div> -->

      <component
        :is="`v-radio`"
        v-for="(item, index) in radioList"
        :key="`${item.value}-${index}`"
        :label="item.label"
        :value="item.value"
        v-bind="item.componentProps"
        dense
        hide-details
        v-on="item.eventHandlers"
      >
        <template #label>
          <span>{{ item.label }}</span>
          <slot name="radio" :row="item"></slot>
        </template>
      </component>
      {{ getDataWithKey(remark, 'value') }}
    </component>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { getDataWithKey } from '@core/utils/dataProcess'
import { VRadioGroup, VRadio } from 'vuetify/lib/components'

export default {
  name: 'RadioGroup',
  components: {
    VRadioGroup,
    VRadio,
  },
  model: {
    prop: 'dataModel',
    event: 'update:data-model',
  },
  props: {
    dataModel: {
      type: [Boolean, Number, String, Object, Array, null],
      default: 0,
    },
    label: {
      type: String,
      default: '请选择：',
    },
    remark: {
      type: String,
      default: '',
    },
    row: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: [Boolean, String, Object],
      default: false,
    },
    rules: {
      type: Array,
      default: Array,
    },
    radioGroupProps: {
      type: Object,
      default: Object,
    },
    radioList: {
      type: Array,
      default: Array,
    },
  },

  setup(props, { emit }) {
    const data = computed({
      get: () => props.dataModel,
      set: val => emit('update:data-model', val),
    })

    return {
      data,
      getDataWithKey,
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-input--selection-controls{
  margin-top: 3px;
}
</style>
