<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'DescGroup',
  model: {
    prop: 'descGroupModel',
    event: 'update:desc-group-model',
  },
  props: {
    descGroupModel: {
      type: Object,
      default: Object,
    },
  },
  setup() {

  },
}
</script>

<style scoped>

</style>
