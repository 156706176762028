import mapEnum from './mapEnum'

const list = [
  { text: 'CP名称', value: 'cpName', minWidth: 180, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 150, showOverflow: false },
  { text: '发票类型', value: 'type', minWidth: 80, showOverflow: false, enum: mapEnum.typeOptions },
  { text: '发票数量', value: 'vatNum', minWidth: 120, showOverflow: false, align: 'right' },
  { text: '关联结算单', value: 'settlementList', minWidth: 200, showOverflow: false },
  { text: '关联结算单金额', value: 'revenue', minWidth: 120, align: 'right' },
  { text: '快递单号', value: 'expressNum', minWidth: 200 },
  { text: '发票审核状态', value: 'checkStatus', minWidth: 120, enum: mapEnum.checkStatusOptions },
]

export const tableColumns1 = [
  ...list,
  { text: '上传时间', value: 'uploadedAt', width: 200 },
  { text: '操作', value: 'actions', width: 120, fixed: 'right' },
]

export const tableColumns2 = [
  { text: '', value: 'checkbox', type: 'checkbox', width: 50 },
  ...list,
  { text: '原因', value: 'reason', width: 200, showOverflow: false },
  { text: '汇款状态', value: 'paymentStatus', minWidth: 120, showOverflow: false },
  { text: '附件', value: 'reviewAnnex', width: 280, showOverflow: false },
  { text: '上传时间', value: 'uploadedAt', width: 200 },
  { text: '审核人', value: 'reviewer', width: 120 },
  { text: '审核时间', value: 'reviewedAt', width: 200 },
  { text: '最后修改人', value: 'updatedUserName', minWidth: 120 },
  { text: '最后修改时间', value: 'updatedAt', minWidth: 200 },
  { text: '操作', value: 'actions', width: 120, fixed: 'right' },
]

export const tableColumns3 = [
  ...list,
  { text: '原因', value: 'reason', width: 200, showOverflow: false },
  { text: '附件', value: 'reviewAnnex', width: 280, showOverflow: false },
  { text: '上传时间', value: 'uploadedAt', width: 200 },
  { text: '审核人', value: 'reviewer', width: 120 },
  { text: '审核时间', value: 'reviewedAt', width: 200 },
  { text: '操作', value: 'actions', width: 120, fixed: 'right' },
]

export const viewHeader = [
  { text: '发票', value: 'fileName' },
  { text: '票种', value: 'type', width: 180 },
  { text: '数电票号码', value: 'digitalTicketNumber', width: 250 },
  { text: '发票代码', value: 'invoiceCode', width: 180 },
  { text: '发票号码', value: 'invoiceNum', width: 160 },
]
