<script>
import { useMessage } from '@/hooks/useMessage'
import { computed, onMounted, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { approved, approvedDetail } from '../service'

export default {
  name: 'AuditDialog',
  components: { Dialog },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const { message } = useMessage()
    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const isActive = ref(true)
    const submit = () => {
      approved(props.form.id).then(({ data }) => {
        if (data.status === 200) message.success('审核成功')
        emit('refresh')
        close()
      }).catch(error => message.error(error.response.data.message))
    }

    const currentMonth = ref({
      revenue: undefined,
      serviceCharge: undefined,
      federalTax: undefined,
      proportion: undefined,
      bankAccount: undefined,
      bankAccountName: undefined,
      idCard: undefined,
      openingBank: undefined,
      actualIncomeDollar: undefined,
    })
    const lastMonth = ref({
      revenue: undefined,
      serviceCharge: undefined,
      federalTax: undefined,
      proportion: undefined,
      bankAccount: undefined,
      bankAccountName: undefined,
      idCard: undefined,
      openingBank: undefined,
      actualIncomeDollar: undefined,
    })

    const currentMonthList = computed(() => {
      const { revenue, serviceCharge, proportion, bankAccount, bankAccountName, idCard, openingBank, actualIncomeDollar } = currentMonth.value
      const { serviceCharge: serviceCharge1, proportion: proportion1, bankAccount: bankAccount1, bankAccountName: bankAccountName1, idCard: idCard1, openingBank: openingBank1 } = lastMonth.value

      return [
        { label: '频道收益', value: `${revenue ? `$ ${revenue}` : '-'}`, flag: true },
        { label: 'CP分成比例', value: `${proportion ? `${proportion}%` : '-'}`, flag: isActive.value ? proportion === proportion1 : true },
        // { label: '联邦税率', value: `${federalTax ? `${federalTax}%` : '-'}`, flag: isActive.value ? federalTax === federalTax1 : true },
        { label: '手续费率', value: `${serviceCharge ? `${serviceCharge}%` : '-'}`, flag: isActive.value ? serviceCharge === serviceCharge1 : true },
        { label: '实发', value: `${actualIncomeDollar ? `$ ${actualIncomeDollar}` : '-'}`, flag: true },
        { label: '收款人', value: bankAccountName || '-', flag: isActive.value ? bankAccountName === bankAccountName1 : true },
        { label: '证件号码', value: idCard || '-', flag: isActive.value ? idCard === idCard1 : true },
        { label: '开户行', value: openingBank || '-', flag: isActive.value ? openingBank === openingBank1 : true },
        { label: '银行卡号', value: bankAccount || '-', flag: isActive.value ? bankAccount === bankAccount1 : true },
      ]
    })
    const lastMonthList = computed(() => {
      const { revenue, serviceCharge, proportion, bankAccount, bankAccountName, idCard, openingBank, actualIncomeDollar } = lastMonth.value

      return [
        { label: '频道收益', value: `${revenue ? `$ ${revenue}` : '-'}` },
        { label: 'CP分成比例', value: `${proportion ? `${proportion}%` : '-'}` },
        // { label: '联邦税率', value: `${federalTax ? `${federalTax}%` : '-'}` },
        { label: '手续费率', value: `${serviceCharge ? `${serviceCharge}%` : '-'}` },
        { label: '实发', value: `${actualIncomeDollar ? `$ ${actualIncomeDollar}` : '-'}` },
        { label: '收款人', value: bankAccountName || '-' },
        { label: '证件号码', value: idCard || '-' },
        { label: '开户行', value: openingBank || '-' },
        { label: '银行卡号', value: bankAccount || '-' },
      ]
    })
    const getDetail = () => {
      approvedDetail(props.form.id).then(({ data }) => {
        if (data.data.currentMonth) {
          currentMonth.value = data.data.currentMonth
        }
        if (data.data.lastMonth) {
          lastMonth.value = data.data.lastMonth
          isActive.value = true
        } else {
          isActive.value = false
        }
      }).catch(error => message.error(error.response.data.message))
    }
    onMounted(() => {
      getDetail()
    })

    return {
      show,
      close,
      submit,
      currentMonthList,
      lastMonthList,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    show-footer
    :title="`${form.cpName}-${form.channelName}${form.subsetName ? `(${form.subsetName})` : ''}-${form.month.replace(/-/g, '')}`"
    confirm-btn-text="审核通过"
    width="780px"
    @confirm="submit"
    @cancel="close"
  >
    <div class="d-flex">
      <div class="left common">
        <div class="title">
          本月结算单
        </div>
        <div class="list">
          <div v-for="item,index in currentMonthList" :key="index" :class="['item',{active: !item.flag}]">
            <span>{{ item.label }}</span>
            <span>{{ item.value }}</span>
          </div>
        </div>
      </div>
      <div class="right common">
        <div class="title">
          上月结算单
        </div>
        <div class="list">
          <div v-for="item,index in lastMonthList" :key="index" class="item">
            <span>{{ item.label }}</span>
            <span>{{ item.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<style scoped lang='scss'>
.common{
    width: 50%;
    padding: 16px 20px;
    border: 1px dashed #E8E9EC;
.title{
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

.list{
    border: 1px solid #E0D1B8;
    margin-top: 15px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 15px;
    color: rgba(0,0,0,0.8);
    .item{
        border-bottom: 1px solid #E0D1B8;
        display: flex;
        span{
            width: 130px;
            padding: 10px 12px;
            border-right:  1px solid #E0D1B8;
            &:last-child{
                border-right: none;
                flex: 1;
            }
        }

        &:last-child{
            border-bottom: none;
        }

        &.active{
            background: rgba(242,69,69,0.08);
            color: #F24545;
        }
    }
}

}
.left{
    background-color: rgba(255,168,15,0.04);
    border-radius: 4px 0px 0px 4px;
}
.right{
    background-color: #F6F7F8;
    border-radius: 0px 4px 4px 0px;
    border-left: none;

    .title{
        font-weight: 400;
    }
    .list{
        color: rgba(0,0,0,0.5);
        border: 1px solid #D6DDE3;
        .item{
            border-bottom: 1px solid #D6DDE3;
            span{
                border-right:  1px solid #D6DDE3;
            }
        }
    }
}

</style>
