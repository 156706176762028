import { proformaUpdateBatchCount, proformaUpdateBatchPaymentStatus, vatUpdateBatchCount, vatUpdateBatchPaymentStatus, outseaUpdateBatchCount, outseaUpdateBatchPaymentStatus } from './service'

export const getBatchCountMap = {
  proforma: proformaUpdateBatchCount,
  vat: vatUpdateBatchCount,
  outsea: outseaUpdateBatchCount,
}

export const submitBatchPaymentStatusMap = {
  proforma: proformaUpdateBatchPaymentStatus,
  vat: vatUpdateBatchPaymentStatus,
  outsea: outseaUpdateBatchPaymentStatus,
}
