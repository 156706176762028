<script>
import TooltipBase from '@/views/components/common/tooltip-base/TooltipBase.vue'
import { statusPointColorMap } from '@/@core/utils/colorMap'
import { formatNumber, processNumbers, addSpace } from '@/@core/utils'
import { downloadFile } from '@/@core/utils/form'
import Pagination from '@/components/Pagination.vue'
import { useMessage } from '@/hooks/useMessage'
import SuperTable from '@/components/SuperTable.vue'
import moment from 'moment'
import { ref, reactive, onMounted, nextTick, computed } from '@vue/composition-api'
import AppView from '@/components/AppView.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import RealAdjustmentDialog from './RealAdjustmentDialog.vue'
import { remittanceStatisticsFB, actualIncomeRmbDiffExportExportFB } from '../service'
import CreateFinalDialog from '../../ytAccountForm/components/createFinalDialog.vue'
import mapEnum, { apiMap, exportMap } from '../mapEnum'

export default {
  name: 'Provisional',
  components: { AppView, SearchForm, SuperTable, Pagination, CreateFinalDialog, RealAdjustmentDialog, TooltipBase },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    header: {
      type: Array,
      default: () => [],
    },
    tabList: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: String,
      default: 'zg',
    },
    activeName: {
      type: String,
      default: 'zg',
    },
    label: {
      type: String,
      default: '暂估报表',
    },
  },
  setup(props) {
    const form = ref()
    const table = ref()
    const loading = ref(false)
    const { message } = useMessage()

    const searchForm = computed(() => props.formData).value

    const times = computed(() => ({
      monthStart: searchForm?.month[0],
      monthEnd: searchForm?.month[1],
      payoutPeriodStart: searchForm?.month[0],
      payoutPeriodEnd: searchForm?.month[1],
    }))

    const page = reactive({
      total: 0,
      page: 1,
      pageSize: 20,
    })
    const visibleCreateFinal = ref(false)
    const visibleRealAdjustment = ref(false)
    const isShow = ref(true)
    const selected = ref([])
    const formConfig = computed(() => [
      {
        el: 'DateMonthRange',
        props: 'month',
        label: '到账月份',
        type: 'month',
        clearable: false,
        isShow: !isShow.value,
      },
      {
        el: 'DateMonthRange',
        props: 'month',
        label: 'Payout Period',
        type: 'month',
        clearable: false,
        isShow: isShow.value,
      },
      {
        el: 'VTextField',
        props: 'objectName',
        label: 'Object Name',
        isShow: true,
      },
      {
        el: 'VTextField',
        props: 'objectId',
        label: 'Object ID',
        isShow: true,
      },
      {
        el: 'VSelect',
        props: 'channelType',
        label: '频道类型',
        items: mapEnum.channelTypeOptions,
        itemText: 'label',
        itemValue: 'value',
        isShow: true,
      },
      {
        el: 'VTextField',
        props: 'subsetName',
        label: '子集名称',
        isShow: true,
      },
      {
        el: 'VSelect',
        props: 'hasContract',
        label: '是否找到合约',
        items: mapEnum.hasContractOptions,
        itemText: 'label',
        itemValue: 'value',
        isShow: true,
      },
      {
        el: 'VSelect',
        props: 'settlementCreatedStatus',
        label: '结算单生成状态',
        items: mapEnum.statusOptions,
        itemText: 'label',
        itemValue: 'value',
        isShow: !isShow.value,
      },
    ])

    const searchConfig = computed(() => formConfig.value.filter(item => item.isShow))
    const tableData = ref([])
    const statisticsForm = ref({})

    const init = () => {
      loading.value = true
      const { month, payoutPeriod, ...args } = searchForm
      apiMap[props.tab]({ ...page, total: undefined, ...args, ...times.value }, props.tab).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, props.header)
        page.total = data.total
        selected.value = []
        table.value.clear()
      })
        .catch(error => {
          loading.value = false
          tableData.value = []
          page.total = 0
          message.error(error.response.data.message)
        })
    }

    const getStatisticsForm = () => {
      remittanceStatisticsFB(times.value).then(({ data }) => {
        statisticsForm.value = (data.data || {})
      }).catch(error => message.error(error.response.data.message))
    }

    const created = () => {
      init()
      if (props.tab === 'remittance') {
        getStatisticsForm()
      }
    }

    const search = () => {
      page.page = 1
      created()
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
      const { month, payoutPeriod, ...args } = searchForm
      exportMap[props.tab]({ ...page, total: undefined, ...args, ...times.value }, props.tab)
        .then(data => {
          const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的FB${props.label}报表`
          downloadFile(data.data, fileNameDownload)
        })
        .catch(error => message.error(error.response.data.message))
    }

    /**
     * 导出实发调差报表
     */
    const exportDifference = () => {
      message.info('导出中...请稍等')
      const { month, payoutPeriod, ...args } = searchForm
      actualIncomeRmbDiffExportExportFB({ ...page, total: undefined, ...args, ...times.value }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的FB${props.label}实发差值明细报表`
        downloadFile(data.data, fileNameDownload)
      })
    }

    const checkBoxChange = ({ records }) => {
      selected.value = records
    }

    const selectAllEvent = ({ records }) => {
      selected.value = records
    }

    /**
     * 生成结算单
     */
    const createFinal = () => {
      if (times.value.monthStart === times.value.monthEnd) {
        visibleCreateFinal.value = true
      } else {
        message.warning('请选择同一月份')
      }
    }
    const realAdjustmentFrom = reactive({})
    /**
     * 实发调差
     */
    const realAdjustment = item => {
      realAdjustmentFrom.incomeRmb = item.incomeRmb
      realAdjustmentFrom.id = item.id
      visibleRealAdjustment.value = true
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return data ? data.label : val || '-'
    }

    const reset = async () => {
      await form.value.resetForm()
      nextTick(() => {
        searchForm.month = [moment().subtract(1, 'month').format('yyyy-MM'), moment().subtract(1, 'month').format('yyyy-MM')]
        created()
      })
    }

    onMounted(() => {
      if (props.activeName === props.tab) {
        created()
      }
    })

    return {
      searchConfig,
      searchForm,
      tableData,
      reset,
      search,
      exportExcel,
      page,
      handleCurrentChange,
      handleSizeChange,
      form,
      filterMap,
      isShow,
      loading,
      createFinal,
      visibleCreateFinal,
      checkBoxChange,
      selectAllEvent,
      selected,
      init,
      table,
      statusPointColorMap,
      statisticsForm,
      exportDifference,
      realAdjustment,
      visibleRealAdjustment,
      realAdjustmentFrom,
      created,
      processNumbers,
      addSpace,
    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div class="flex-vertical" style="width: 100%">
        <SearchForm ref="form" :columns="searchConfig" :value="searchForm" :search="search" :reset="reset" />
      </div>
    </template>
    <template #main-header>
      <div class="flex-between">
        <div class="flex-center">
          <v-btn v-if="tab === 'remittance'" v-auth="['fbAccountForm-generate-statement']" color="primary" @click="createFinal">
            生成结算单
          </v-btn>
          <div class="d-flex">
            <v-btn v-if="tab === 'remittance'" v-auth="['fbAccountForm-remittance-export']" outlined class="ml-2" @click="exportExcel">
              导出报表
            </v-btn>
            <div v-else>
              <div v-for="item in tabList.filter(item => item.value !== 'remittance')" :Key="item.value">
                <v-btn v-if="item.value === tab" v-auth="item.auth" color="primary" @click="exportExcel">
                  {{ addSpace('导出') }}
                </v-btn>
              </div>
            </div>
          </div>

          <v-btn v-if="tab === 'remittance'" v-auth="['fbAccountForm-detail-export']" outlined class="ml-2" @click="exportDifference">
            导出实发差值明细
          </v-btn>
        </div>

        <div v-if="tab === 'remittance'" class="num">
          <span>Payout:<span class="amount"> {{ processNumbers(statisticsForm.payout,'$') }} </span></span>
          <span>Tax:<span class="amount"> {{ processNumbers(statisticsForm.tax,'$') }} </span></span>
          <span>RM:<span class="amount"> {{ processNumbers(statisticsForm.remittance,'$') }} </span></span>
          <span>实收RM:<span class="amount"> {{ processNumbers(statisticsForm.remittanceReal,'$') }} </span></span>
          <span>RM差值:<span class="amount"> {{ processNumbers(statisticsForm.remittanceDifference,'$') }} </span></span>

          <TooltipBase title="结算单状态=已生成的实发，非全部数据的实发">
            <i class="iconfont icon-a-shuoming2x1 mr5 fs14"></i>
          </TooltipBase>

          <span>实发 (￥):<span class="amount"> {{ processNumbers(statisticsForm.actualIncomeRmb,'￥') }} </span></span>
          <span>结算单实发 (￥):<span class="amount"> {{ processNumbers(statisticsForm.settlementActualIncomeRmb,'￥') }} </span></span>
          <span>实发 (￥) 差值:<span class="amount"> {{ processNumbers(statisticsForm.actualIncomeRmbDifference,'￥') }} </span></span>
        </div>
      </div>
    </template>
    <SuperTable
      ref="table"
      :columns="header"
      :data="tableData"
      :loading="loading"
      @checkBoxChange="checkBoxChange"
      @selectAllEvent="selectAllEvent"
    >
      <template #objectName="{ row }">
        <div>{{ row.objectName }}</div>
        <div>{{ row.objectId }}</div>
      </template>

      <template #proportion="{ row }">
        {{ row.proportion ? `${row.proportion}%` : '-' }}
      </template>
      <template #serviceCharge="{ row }">
        {{ row.serviceCharge ? `${row.serviceCharge}%` : '-' }}
      </template>
      <template #settlementCreatedStatus="{ row }">
        <div class="sortBox">
          <div class="point" :style="{ background: statusPointColorMap[row.settlementCreatedStatus] }"></div>
          <div>{{ filterMap('statusOptions', row.settlementCreatedStatus) }}</div>
        </div>
      </template>
      <template #actions="{ row }">
        <span v-if="row.settlementCreatedStatus === 1" v-auth="['fbAccountForm-real-adjustment']" class="app-link" @click="realAdjustment(row)">实发调差</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <CreateFinalDialog
      v-if="visibleCreateFinal"
      :string-list="['1、确保当前月份频道数量和整体收益正确', '2、未找到合约和已生成结算单，不生成结算单']"
      :form="{ data: selected, month: searchForm.month, platform: 'FB' }"
      @close="visibleCreateFinal = false"
      @refresh="init"
    />

    <RealAdjustmentDialog v-if="visibleRealAdjustment" :form="realAdjustmentFrom" @close="visibleRealAdjustment = false" @refresh="created" />
  </AppView>
</template>
<style scoped lang="scss">
span {
  cursor: pointer;
}

.num{
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 20px;
  font-size: 14px;
  .amount{
      color: #FFA80F;
      padding: 0 10px 18px  0;
  }
  }

::v-deep .app-view__main {
  overflow: hidden;
}

::v-deep .vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon {
  color: #9155fd !important;
}
::v-deep .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: #9155fd !important;
}
::v-deep .vxe-table--render-default .is--checked.vxe-cell--checkbox {
  color: #606266 !important;
}
::v-deep .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox {
  color: #606266 !important;
}
</style>
