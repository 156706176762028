const mapEnum = {

  platformType: [
    { label: 'YT', value: 'YT' },
    { label: 'FB', value: 'FB' },
  ],
  statusOptions: [
    { label: '未生成', value: 0 },
    { label: '已生成', value: 1 },
    { label: '生成失败', value: 2 },
  ],
  typeOptions: [
    { label: '全部生成', value: 0 },
    { label: '勾选生成', value: 1 },
  ],

}

export default mapEnum
