<script>
import { isImgFormat } from '@/@core/utils/is'
import { adjustCurrencyMap } from '@/@core/utils/constant'
import DialogTable from '@/components/Dialog/DialogTable.vue'
import SuperTable from '@/components/SuperTable.vue'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import { AliOSS } from '@core/utils/useAliOSS'
import { invoiceStatusOptions } from '@/@core/utils/options'
import PreviewImg from '@/components/PreviewImg/index.vue'
import { formatNumber as formatNumberData, addThousandth, filterMap } from '@/@core/utils'
import { header, adjustmentHeader } from './tabCols'
import Steps from './Steps.vue'
import { detail, pdfDownload, productByNo, adjustmentRecord } from './service'
import ChartBar from './ChartBar.vue'

export default {
  name: 'Details',
  components: { ChartBar, Steps, SuperTable, DialogTable, PreviewImg },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { loading, message } = useMessage()
    const show = ref(true)
    const tableData = ref([])
    const adjustmentData = ref([])
    const aliOss = new AliOSS()

    aliOss.fetchOSS()
    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }
    watch(() => show.value, newVal => {
      if (!newVal) emit('close')
    })

    const previewVisible = ref(false)

    function formatNumber(number) {
      if (!number) return '-'

      return Number(number).toLocaleString()
    }

    const processNumbers = (number, unit = '') => {
      if (!number) return '-'

      return `${unit} ${addThousandth(number)}`
    }

    const formData = ref({
      checkList: {
        total: 4,
        checkDetailVOList: [],
      },
    })
    const checkPointList = computed(() => formData.value.checkList.checkDetailVOList)

    const val = computed(() => {
      const res = checkPointList.value.length

      if (formData.value.checkList.total === 4) {
        if (res === 3) {
          return res + 2
        }
      }

      if (formData.value.checkList.total === 5) {
        if (res === 4) {
          return res + 2
        }
      }

      return res + 1
    })

    const titleMap1 = {
      1: '结算单生成',
      2: '客服审核',
      3: '财务审核',
      4: '客服二审',
      5: '已推送',
      6: '已推送',
    }

    const titleMap2 = {
      1: '结算单生成',
      2: '财务审核',
      3: '客服二审',
      4: '已推送',
      5: '已推送',
    }

    const platformMap = {
      YT: 'YouTube',
      FB: 'FackBook',
    }
    const platformTextMap = {
      YT: '频道',
      FB: '主页',
    }

    const platformContentMap = {
      YT: '联邦税费',
      FB: 'FB平台预提税',
    }

    const stepperList = computed(() => [
      { title: '结算单生成', point: 0, isShow: true },
      { title: '客服审核', point: 1, isShow: formData.value.checkList.total === 5 },
      { title: '财务审核', point: 2, isShow: true },
      { title: '客服二审', point: 3, isShow: true },
      { title: '已推送', point: 4, isShow: true },
    ])

    const newStepperList = computed(() => {
      const newArr = stepperList.value.filter(item => item.isShow)

      return newArr.map(item => {
        const data = (checkPointList.value || []).find(ele => ele.checkPoint === item.point)

        return {
          ...item,
          checkForm: data,
        }
      })
    })

    const msgList = computed(() => {
      const { cpName, channelName, channelId, month, bankAccountName, idCard, openingBank, bankAccount, subsetName, invoiceStatus, paymentStatus } = formData.value

      return [
        { name: 'CP名称', msg: cpName || '-' },
        { name: `${platformTextMap[props.form.platform]}名称`, msg: channelName || '-' },
        { name: `${platformTextMap[props.form.platform]}ID`, msg: channelId || '-' },
        { name: 'Payout Period', msg: month || '-' },
        { name: '子集名称', msg: subsetName || '-' },
        { name: '收款人', msg: bankAccountName || '-' },
        { name: '证件号码', msg: idCard || '-' },
        { name: '开户行', msg: openingBank || '-' },
        { name: '收款账号', msg: bankAccount || '-' },
        { name: '结算单状态', msg: formData.value.checkList.total === 5 ? titleMap1[val.value] : titleMap2[val.value] },
        { name: '发票状态', msg: filterMap(invoiceStatusOptions, invoiceStatus) },
        { name: '汇款状态', msg: paymentStatus || '-' },
      ]
    })

    const dataList = computed(() => {
      const { views, revenue, revenueUs, federalTaxAmount, distributableIncome, proportion, expectedIncome, serviceChargeDollar, serviceCharge, actualIncomeDollar, rate, actualIncomeRmb, withholdingTax, adjustAmount, adjustCurrency } = formData.value

      return [
        { text: '播放量', subtitleText: `*以 ${platformMap[props.form.platform]} 平台导出数据为准`, img: require('../assets/img1@2x.png'), num: processNumbers(views), auth: true },
        { text: `${platformTextMap[props.form.platform]}收益`, subtitleText: `*以 ${platformMap[props.form.platform]} 平台导出数据为准`, img: require('../assets/img2@2x.png'), num: processNumbers(revenue, '$'), auth: true },
        { text: '美国收益', subtitleText: `*以 ${platformMap[props.form.platform]} 平台导出数据为准`, img: require('../assets/img3@2x.png'), num: processNumbers(revenueUs, '$'), auth: props.form.platform === 'YT' },
        { text: `${platformContentMap[props.form.platform]}`, subtitleText: `*以 ${platformMap[props.form.platform]} 平台代扣代缴`, img: require('../assets/img4@2x.png'), num: props.form.platform === 'YT' ? processNumbers(federalTaxAmount, '$') : processNumbers(withholdingTax, '$'), auth: true },
        { text: '可分配收益', subtitleText: '', img: require('../assets/img5@2x.png'), num: processNumbers(distributableIncome, '$'), auth: true },
        { text: '分成比例', subtitleText: '*客户分成比例 %', img: require('../assets/img6@2x.png'), num: `${formatNumber(proportion || 0)}%` || '-', auth: true },
        { text: '客户收益 (应发)', subtitleText: '', img: require('../assets/img7@2x.png'), num: processNumbers(expectedIncome, '$'), auth: true },
        { text: '付款手续费', subtitleText: '', unit: `(手续费率:${serviceCharge ? `${serviceCharge}%` : '-'})`, img: require('../assets/img8@2x.png'), num: processNumbers(serviceChargeDollar, '$'), auth: true },
        { text: '客户收益 (实发)', subtitleText: '', img: require('../assets/img9@2x.png'), num: processNumbers(actualIncomeDollar, '$'), auth: true },
        { text: '美元汇率', subtitleText: '*参考次月收款日左右的汇率', img: require('../assets/img10@2x.png'), num: processNumbers(rate), auth: true },
        { text: '调整项金额', subtitleText: '', img: require('../assets/img12@3x.png'), num: processNumbers(adjustAmount, adjustCurrencyMap[adjustCurrency]), auth: adjustAmount },
        { text: '客户收益 (实发)', subtitleText: '', img: require('../assets/img11@2x.png'), num: processNumbers(actualIncomeRmb, '￥'), auth: true },
      ]
    })

    const newDataList = computed(() => dataList.value.filter(item => item.auth))
    const getDetail = async () => {
      const loadingRef = loading()
      try {
        const { data } = await detail(props.form.settlementNo)
        loadingRef.close()
        formData.value = data.data
      } catch (error) {
        message.error(error.response.data.message)
        loadingRef.close()
      }
    }

    const getProduct = () => {
      productByNo(props.form.settlementNo).then(({ data }) => {
        tableData.value = formatNumberData(data.data, header)
      }).catch(error => message.error(error.response.data.message))
    }

    const getAdjustmentRecord = async () => {
      try {
        const { data } = await adjustmentRecord(formData.value.id)
        if (!Object.keys(data.data).length) {
          adjustmentData.value = []
        } else {
          adjustmentData.value = formatNumberData([data.data], adjustmentHeader)
        }
      } catch (error) {
        message.error(error.response.data.message)
      }
    }

    // 查看文件
    const filePath = ref('')
    const previewFile = item => {
      const data = aliOss.fetchDownloadLink(item.filePath, item.fileName, false)
      if (!isImgFormat(item.fileName)) {
        window.open(data)
      } else {
        previewVisible.value = true
        filePath.value = data
      }
    }

    // 下载pdf
    const clickDownload = () => {
      const loadingRef = loading()
      if (!formData.value.id) return message.warning('操作太快，请再点击一次')
      pdfDownload(formData.value.id).then(({ data }) => {
        loadingRef.close()
        window.open(data.data)
      }).catch(error => {
        loadingRef.close()
        message.error(error.response.data.message)
      })
    }

    const handleStepChange = step => {
      val.value = step
    }

    const onChange = index => {
      console.log(index)
    }

    onMounted(async () => {
      await getDetail()
      getAdjustmentRecord()
      if (props.form.platform === 'FB') {
        getProduct()
      }
    })

    return {
      show,
      close,
      stepperList,
      val,
      msgList,
      newDataList,
      formData,
      checkPointList,
      newStepperList,
      clickDownload,
      titleMap1,
      titleMap2,
      handleStepChange,
      onChange,
      header,
      adjustmentHeader,
      tableData,
      adjustmentData,
      previewFile,
      filePath,
      previewVisible,
      adjustCurrencyMap,
    }
  },
}
</script>
<template>
  <div>
    <vl-drawer :visible.sync="show" size="1200px" :with-header="true" @before-close="close">
      <template #title>
        <v-card class="d-flex justify-space-between algin-center py16 pr20" style="position: fixed; top: 0; z-index: 1; width: calc(1200px - 20px); height: 70px;">
          <div class="title">
            <img src="../assets/finalIcon@2x.png" alt="">{{ form.settlementNo }}
            <!-- <span>{{ formData.checkList.total === 5 ? titleMap1[val] : titleMap2[val] }}</span> -->
          </div>
          <div>
            <v-btn v-if="form.platform === 'YT'" v-auth="['ytStatement-download-pdf']" color="primary" @click="clickDownload">
              下载PDF
            </v-btn>
            <v-btn v-if="form.platform === 'FB'" v-auth="['fbStatement-download-pdf']" color="primary" @click="clickDownload">
              下载PDF
            </v-btn>
            <v-btn
              icon
              small
              class="ml-2"
              @click="close"
            >
              <v-icon size="22">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </v-card>
      </template>
      <div>
        <div class="py13 px20 mb70" style="background-color: #F6F7F8;">
          <v-card class="pt20 px15">
            <Steps
              v-model="val"
              :steps="newStepperList"
              :width="'100%'"
              :desc-max-width="220"
              @change="onChange"
            />
          </v-card>
          <v-card class="p20 mt12">
            <div class="title">
              <div class="line"></div>
              <div class="text">
                基本信息
              </div>
            </div>
            <div class="pl12 mt16">
              <div class="list">
                <div v-for="item,index in msgList" :key="index" class="item">
                  {{ item.name }}：<span>{{ item.msg }}</span>
                </div>
              </div>
            </div>
          </v-card>

          <v-card class="pb20 pt10 pl5 mt12">
            <v-tabs
              class="tabs-warp"
              height="50px"
            >
              <v-tab>数据详情</v-tab>
              <v-tab v-if="form.platform === 'FB'">
                Product明细
              </v-tab>
              <v-tab>
                调整项记录
              </v-tab>
              <v-tab-item>
                <div class="mx30 mt16">
                  <div class="dataList">
                    <div v-for="item,index in newDataList" :key="index" class="dataItem" :class="{active : newDataList.length % 2 !== 0}">
                      <div class="left">
                        <img :src="item.img" alt="">
                        <div>
                          <div>{{ item.text }} <span>{{ item.unit }}</span></div>
                          <div><span>{{ item.subtitleText }}</span></div>
                        </div>
                      </div>
                      <div class="right">
                        {{ item.num }}
                      </div>
                    </div>
                  </div>

                  <ChartBar :chart-item="{data:formData.echart,id:1}" style="background: #F6F7F8; border-radius: 8px; margin-top: 16px;" />
                </div>
              </v-tab-item>
              <v-tab-item v-if="form.platform === 'FB'">
                <div class="px18 mt16">
                  <SuperTable
                    ref="table"
                    :columns="header"
                    :data="tableData"
                    height="500px"
                  >
                  </SuperTable>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="px18 mt16">
                  <DialogTable
                    ref="table"
                    :columns="adjustmentHeader"
                    :data="adjustmentData"
                    height="280px"
                  >
                    <template #annexList="{row}">
                      <div v-if="(row.annexList || []).length">
                        <div v-for="item in row.annexList" :key="item.filePath">
                          <span class="download-link" @click="previewFile(item)">
                            {{ item.fileName }}
                          </span>
                          <vl-tag :label="item.isShow ? '展示' : '不展示'"></vl-tag>
                        </div>
                      </div>
                      <div v-else>
                        -
                      </div>
                    </template>

                    <template #adjustAmount="{row}">
                      {{ row.adjustAmount ? row.adjustAmount === '0' ? '-' : (adjustCurrencyMap[row.adjustCurrency] + row.adjustAmount) : '-' }}
                    </template>
                  </DialogTable>
                  <div class="remark mt10">
                    注：仅保留最近一条调整记录，若调整有误，请重新发起
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </div>

        <v-card class="px20 py14 d-flex justify-end" style="position: fixed; bottom: 0; z-index: 1; width: 1200px; height: 70px;">
          <v-btn outlined @click="close">
            返回
          </v-btn>
        </v-card>
      </div>
    </vl-drawer>

    <PreviewImg
      v-if="previewVisible"
      :list="[filePath]"
      @close="previewVisible = false"
    />
  </div>
</template>
<style scoped lang='scss'>
::v-deep .v-slide-group__content{
  border-bottom: 1px solid #e8e9ec;
  margin-left: 16px;
}
::v-deep .v-tab{
  text-transform:capitalize;
  font-size: 16px;
  font-weight: 500;
  max-width: none !important;
  min-width: 48px !important;
  padding: 0;
  margin: 0 16px;
  letter-spacing: 0px;
}
::v-deep .v-tabs-slider-wrapper{
  height: 3px;
}
::v-deep .v-application.theme--light .v-stepper:not(.v-sheet--outlined){
    box-shadow: none !important;
}
::v-deep .v-sheet:not(.v-sheet--outlined){
  box-shadow: none !important;
}

::v-deep .v-application.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
::v-deep .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
  box-shadow: none !important;
}
::v-deep .v-tabs.tabs-warp.theme--light {
  box-shadow: none !important;
}

::v-deep .vl-tag{
  display: inline-block;
}
::v-deep .vxe-table--render-default .vxe-cell{
  white-space:unset;
}
.title{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    img{
        width: 20px;
        height: 20px;
        margin: 0 5px;
    }
    span{
        margin: 0 5px;
        font-weight: 500;
        font-size: 12px;
        color: #FFA80F;
        background: rgba(255,168,15,0.08);
        border-radius: 4px;
        padding: 0px 8px;
    }
}
.line{
    width: 4px;
    height: 16px;
    background: #9155FD;
    margin: 0px 8px 0 0;
    }
.text{
    font-weight: 500;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
}
.list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .item{
        margin-bottom: 14px;
        font-size: 14px;
        color: rgba(0,0,0,0.5);
    span{
        color: rgba(0,0,0,0.8);
    }
    }
}

.dataList{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid #E7ECF0;
  border-bottom: none;
  border-radius: 4px;
  color: rgba(0,0,0,0.85);

  .active {
      &:last-child{
      border-right: none;
      grid-column: 1 / -1;
      .left{
        width: 50%;
      }
    }
    }

  .dataItem{
    display: flex;
    align-items: center;
    border-bottom:  1px solid #E7ECF0;
    border-right:  1px solid #E7ECF0;

    &:nth-child(even){
      border-right: none;
    }
    .left{
      display: flex;
      align-items: center;
      width: 65%;
      border-right:  1px solid #E7ECF0;
      padding: 12px 12px 12px  20px;
      img{
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
      span{
        font-size: 12px;
        color: rgba(0,0,0,0.4);
      }

    }
    .right{
      flex: 1;
      padding: 12px 20px;
      font-weight: 500;
    }
  }
}
</style>
