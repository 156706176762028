<script>
import { MessageBox } from 'element-ui'
import { formatNumber, processNumbers, addSpace } from '@/@core/utils'
import SearchForm from '@/components/SearchForm/index.vue'
import { ref, reactive, computed, onMounted, nextTick } from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import AppView from '@/components/AppView.vue'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import { signStatusColorMap, checkStatusColorMap, paymentStatusColorMap } from '@/@core/utils/colorMap'
import Details from '@/views/statementManagement/components/StatementDetail/index.vue'
import { downloadFile } from '@/@core/utils/form'
import BatchChangeRemitDialog from '../../components/batch-change-remit-status/index.vue'
import mapEnum, { filterMap, textMap } from '../mapEnum'
import { getProformaPage, pageStatistics, proformaExport, proformaCancel, proformaRegenerate } from '../service'

export default {
  name: 'CommonTable',
  components: { SuperTable, Pagination, AppView, SearchForm, Details, BatchChangeRemitDialog },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    header: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '全部',
    },
    activeName: {
      type: String,
      default: '全部',
    },
  },
  setup(props, { emit }) {
    const table = ref()
    const selected = ref([])
    const formRef = ref()
    const { message, loading: Loading, MessageBox: MessageBox2 } = useMessage()
    const searchForm = computed(() => props.formData).value
    const loading = ref(false)

    const newSearchForm = computed(() => ({
      ...searchForm,
      payoutPeriodStart: searchForm.payoutPeriod[0],
      payoutPeriodEnd: searchForm.payoutPeriod[1],
      signAtStart: searchForm.signTime[0],
      signAtEnd: searchForm.signTime[1],
      createdAtStart: searchForm.times[0],
      createdAtEnd: searchForm.times[1],
      payoutPeriod: undefined,
      signTime: undefined,
      times: undefined,
      paymentStatus: props.label === '已签字' ? searchForm.paymentStatus : undefined,
    }))

    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })

    const searchConfig = computed(() => [
      {
        el: 'VTextField', props: 'number', label: '编号',
      },
      {
        el: 'VTextField', props: 'cpName', label: 'CP名称',
      },
      {
        el: 'DateMonthRange', props: 'payoutPeriod', label: 'Payout Period',
      },
      {
        el: 'DatePickers', props: 'times', label: '创建时间', clearable: true, range: true,
      },
      {
        el: 'DateTimeRange', props: 'signTime', label: '签字日期', clearable: false, range: true, auth: props.label !== '已签字', span: 2,
      },
      {
        el: 'VSelect', props: 'paymentStatus', label: '汇款状态', items: mapEnum.paymentStatusOptions, itemValue: 'value', itemText: 'label', auth: props.label !== '已签字',
      },
    ])
    const newSearchConfig = computed(() => searchConfig.value.filter(item => !item.auth))
    const statisticsForm = ref({})
    const visibleDetails = ref(false)
    const visibleBatchChangeRemit = ref(false)
    const tableData = ref([])
    const init = () => {
      loading.value = true
      getProformaPage(props.tab, { ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        loading.value = false
        tableData.value = formatNumber(data.data, props.header)
        page.total = data.total
        emit('updateTotal', props.tab)
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const getStatisticsForm = () => {
      pageStatistics(props.tab, { ...page, total: undefined, ...newSearchForm.value }).then(({ data }) => {
        statisticsForm.value = (data.data || {})
      }).catch(error => message.error(error.response.data.message))
    }

    const created = () => {
      init()
      getStatisticsForm()
    }

    const refresh = () => {
      table.value.clear()
      created()
    }

    const exportExcel = () => {
      message.info('导出中...请稍等')
      proformaExport(props.tab, { ...newSearchForm.value }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的形式发票${props.label}报表`
        downloadFile(data.data, fileNameDownload)
      }).catch(error => error)
    }

    // 打开详情弹窗
    const detailForm = ref({})
    const openDetail = (settlementNo, platform) => {
      visibleDetails.value = true
      detailForm.value.settlementNo = settlementNo
      detailForm.value.platform = platform
    }

    // 查看
    const review = url => {
      if (!url) return message.info('PDF生成中，暂不支持查看')
      window.open(url)
    }

    const cancel = id => {
      proformaCancel(id).then(({ data }) => {
        if (data.status === 200) message.success('操作成功')
        created()
      }).catch(error => message.error(error.response.data.message))
    }
    // 待生成作废
    const infoNullify = id => {
      MessageBox2({
        title: '作废',
        message: '作废后，所关联的已推送结算单将全部变为待客服二审，是否确认作废？',
        showCancelButton: true,
        cancelBtnText: addSpace('取消'),
        confirmBtnText: addSpace('确定'),
      }).then(() => {
        cancel(id)
      }).catch(error => error)
    }

    // 作废
    const nullify = id => {
      MessageBox.alert(`
      <p>作废后，所关联的已推送结算单将全部变为待客服二审，是否确认作废？</p>
      <p style="color:#F24545; margin-top:10px;">该形式发票已短信通知CP，请谨慎操作！</p>`, '作废', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        cancelButtonText: addSpace('取消'),
        confirmButtonText: addSpace('确定'),
      }).then(() => {
        cancel(id)
      }).catch(error => error)
    }

    // 重新发起
    const regenerate = id => {
      const loadingRef = Loading()
      proformaRegenerate(id).then(({ data }) => {
        loadingRef.close()
        if (data.status === 200) message.success('操作成功')
        created()
      }).catch(error => {
        loadingRef.close()
        message.error(error.response.data.message)
      })
    }

    // 批量变更汇款状态
    const batchRemitForm = ref({})

    const batchChangeStatus = () => {
      batchRemitForm.value = {
        ids: selected.value.map(item => item.id),
        ...newSearchForm.value,
      }
      visibleBatchChangeRemit.value = true
    }

    const getSettlementNoList = arr => arr.flatMap(({ platform, settlementNoList }) => settlementNoList.map(settlementNo => ({ platform, settlementNo })))

    const selectionChange = data => {
      selected.value = data
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        searchForm.payoutPeriod = []
        searchForm.signTime = []
        created()
      }).catch(error => error)
    }

    const search = () => {
      page.page = 1
      init()
      getStatisticsForm()
    }

    onMounted(() => {
      if (props.activeName === props.label) {
        created()
      }
    })

    return {
      newSearchConfig,
      formRef,
      page,
      tableData,
      reset,
      search,
      init,
      created,
      refresh,
      handleCurrentChange,
      handleSizeChange,
      statisticsForm,
      filterMap,
      searchForm,
      selectionChange,
      table,

      review,
      selected,
      signStatusColorMap,
      checkStatusColorMap,
      paymentStatusColorMap,
      openDetail,
      detailForm,
      visibleDetails,
      getSettlementNoList,
      loading,
      nullify,
      regenerate,
      textMap,
      processNumbers,
      addSpace,
      exportExcel,
      infoNullify,
      visibleBatchChangeRemit,
      batchChangeStatus,
      batchRemitForm,

    }
  },
}
</script>
<template>
  <AppView>
    <template #header>
      <div
        class="flex-vertical"
        style="width: 100%"
      >
        <SearchForm
          ref="formRef"
          :columns="newSearchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>

    <template #main-header>
      <div class="d-flex justify-space-between align-end">
        <div class="d-flex align-center">
          <v-btn v-if="label === '已签字'" v-auth="['proformaInvoice-changestatus']" class="mr12" color="primary" @click="batchChangeStatus">
            批量变更汇款状态
          </v-btn>
          <v-btn v-auth="['proformaInvoice-export']" class="mr12" :color=" label === '已签字' ? '' : 'primary'" :outlined="label === '已签字' ? true : false" @click="exportExcel">
            {{ addSpace('导出') }}
          </v-btn>
          <div class="error--text fs14">
            {{ textMap[tab] }}
          </div>
          <div v-if="tab === 2 && selected.length" class="fs14">
            已选择 <span class="app-link">{{ selected.length }}</span> 条
          </div>
        </div>
        <div class="num">
          <span> 审核通过金额: <span class="amount"> {{ processNumbers(statisticsForm.revenue, '￥' ) }} </span></span>
        </div>
      </div>
    </template>

    <SuperTable
      ref="table"
      :columns="header"
      :data="tableData"
      :loading="loading"
      :checkbox-config="{reserve:true}"
      :row-config="{keyField:'id'}"
      @selection-change="selectionChange"
    >
      <template #settlementList="{row}">
        <div v-if="row.settlementList">
          <el-popover
            v-if="getSettlementNoList(row.settlementList).length > 3"
            placement="right"
            width="200"
            trigger="hover"
          >
            <div slot="reference">
              <div v-for="item,index in getSettlementNoList(row.settlementList)" :key="index">
                <span v-if="index <=2" class="download-link" @click="openDetail(item.settlementNo,item.platform)"> {{ item.settlementNo }}</span>
                <span v-if="getSettlementNoList(row.settlementList).length > 3 && index === 2" class="download-link ml5">更多</span>
              </div>
            </div>

            <div class="popoverList">
              <div v-for="item1,index1 in row.settlementList" :key="index1">
                <div>{{ item1.platform }}</div>
                <div v-for="ele,i in item1.settlementNoList" :key="i">
                  <span class="download-link" @click="openDetail(ele,item1.platform)"> {{ ele }}</span>
                </div>
              </div>
            </div>
          </el-popover>

          <div v-else>
            <div v-for="item,index in row.settlementList" :key="index" slot="reference">
              <div v-for="ele,i in item.settlementNoList" :key="i">
                <span v-if="i <= 2" class="download-link" @click="openDetail(ele,item.platform)"> {{ ele }}</span>
                <span v-if="getSettlementNoList(row.settlementList).length > 3 && i === 2" class="download-link ml5">更多</span>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          -
        </div>
      </template>
      <template #signStatus="{row}">
        <div class="sortBox">
          <div class="point" :style="{ background: signStatusColorMap[row.signStatus]}"></div>
          <div>{{ filterMap('signStatusOptions',row.signStatus) }}</div>
        </div>
      </template>
      <template #paymentStatus="{row}">
        <div class="sortBox">
          <div class="point" :style="{ background: paymentStatusColorMap[row.paymentStatus]}"></div>
          <div>{{ row.paymentStatus || '-' }}</div>
        </div>
      </template>
      <template #updatedAt="{row}">
        <div v-if="label === '已签字'">
          {{ row.paymentStatus === '已汇款' ? row.updatedAt : '-' }}
        </div>
        <div v-else>
          {{ row.updatedAt || '-' }}
        </div>
      </template>
      <template #actions="{row}">
        <span v-if="['待发起','发起失败','待签字','已签字'].includes(label)" v-auth="['proformaInvoice-view']" class="app-link" @click="review(row.pdfUrl)">查看</span>
        <span v-if="['待生成'].includes(label)" v-auth="['proformaInvoice-cancel']" class="app-link" @click="infoNullify(row.id)">作废</span>
        <span v-if="['待发起','发起失败','待签字'].includes(label)" v-auth="['proformaInvoice-cancel']" class="app-link" @click="nullify(row.id)">作废</span>
        <span v-if="['发起失败'].includes(label)" v-auth="['proformaInvoice-relaunch']" class="app-link" @click="regenerate(row.id)">重新发起</span>
      </template>
    </SuperTable>

    <template #footer>
      <Pagination
        :page-size="page.pageSize"
        :page-index="page.page"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>

    <Details v-if="visibleDetails" :form="detailForm" @close="visibleDetails = false" />

    <BatchChangeRemitDialog v-if="visibleBatchChangeRemit" :form="batchRemitForm" platform="proforma" :tab="tab" @close="visibleBatchChangeRemit = false" @refresh="refresh" />
  </AppView>
</template>
  <style scoped lang='scss'>
  .num{
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 10px;
  .amount{
      color: #FFA80F;
      padding: 0 10px;
  }
  }

::v-deep .vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon {
  color: #9155fd !important;
}
::v-deep .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: #9155fd !important;
}
::v-deep .vxe-table--render-default .is--checked.vxe-cell--checkbox {
  color: #606266 !important;
}
::v-deep .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox {
  color: #606266 !important;
}

.download-link{
  &:hover{
    color: #0064AF;
  }
}

.popoverList{
  overflow-y: auto;
  max-height: 500px;
}
  </style>
